import {
    Dialog,
    DialogTitle,
    DialogContent,
    Button,
    DialogActions
} from "@mui/material";

import { DynamicFormComponentProps } from "..";
import { DynamicFormRender } from "../DynamicFormRender";


type PreviewModalProps = {
    components: Array<DynamicFormComponentProps>;
    onClose: () => void;
};

export function PreviewModal({ onClose, components }: PreviewModalProps) {
    return (
        <Dialog open={true} onClose={onClose} maxWidth="xs" fullWidth>
            <DialogTitle variant='h6' id="preview-dialog-title">
                Visualizar componentes
            </DialogTitle>
            <DialogContent>
                <DynamicFormRender components={components} />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Fechar</Button>
            </DialogActions>
        </Dialog>
    );
}