import {
    Button,
    CircularProgress,
    Grid,
    Typography,
    Box,
    Paper,
} from "@mui/material";

import CodeIcon from '@mui/icons-material/Code';

import AceEditor from "react-ace";

import "ace-builds/src-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/worker-javascript";
import "ace-builds/src-noconflict/mode-javascript";

import 'ace-builds/webpack-resolver'
import 'ace-builds'
import { useContext, useState } from "react";
import { enqueueSnackbar } from "notistack";
import axiosClient from "../../../../../../libs/axios";
import { CompanyContext } from "../../../../../../contexts/CompanyContext";
import CodeTemplateDialog from "./CodeTemplateDialog";

type CodeEditorProps = {
    value: string;
    onChange: (value: string) => void;
};

export function CodeEditor({ value, onChange }: CodeEditorProps) {

    const [dialogFromTemplate, setDialogFromTemplate] = useState<boolean>(false);
    const [selectedFromTemplate, setSelectedFromTemplate] = useState<boolean>(false);

    const [testing, setTesting] = useState(false);
    const { plan } = useContext(CompanyContext);

    const isTrialPlan = plan === 'TRIAL';

    const handleTest = async () => {

        setTesting(true);

        try {
            let response = await axiosClient.post(`/flow/test-code`, { code: value });
            enqueueSnackbar('O código executado retornou "' + response.data + '"', { variant: 'success' });
        } catch (error: any) {
            enqueueSnackbar(error?.message, { variant: 'error' });
        } finally {
            setTesting(false);
        }
    }

    return (
        <>
            <Grid container my={2} justifyContent="space-between">
                <Grid item>
                    <Typography variant="h6">Código para execução</Typography>
                </Grid>
                <Grid item>
                    <Button
                        sx={{ marginRight: 1 }}
                        variant="outlined" 
                        startIcon={testing ? <CircularProgress size={14} /> : <CodeIcon />} 
                        onClick={() => setDialogFromTemplate(true)}
                        disabled={testing || isTrialPlan}
                    >
                        {selectedFromTemplate ? "Trocar template" : "Selecionar template"}
                    </Button>
                    <Button 
                        variant="outlined" 
                        startIcon={testing ? <CircularProgress size={14} /> : <CodeIcon />} 
                        onClick={handleTest}
                        disabled={testing || isTrialPlan}>
                            Testar
                    </Button>
                </Grid>
            </Grid>
            <Box sx={{ position: 'relative' }}>
                <AceEditor
                    mode="javascript"
                    theme="twilight"
                    name="embedCodeEditor"
                    fontSize={16}
                    showPrintMargin={false}
                    showGutter={true}
                    highlightActiveLine={true}
                    onChange={onChange}
                    value={value}
                    readOnly={isTrialPlan}
                    style={{ width: '100%', height: '400px' }}
                    setOptions={{
                        enableBasicAutocompletion: true,
                        enableLiveAutocompletion: true,
                        enableSnippets: true,
                        showLineNumbers: true,
                        tabSize: 2,
                        useWorker: true,
                    }}
                />
                {isTrialPlan && (
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backgroundColor: 'rgba(0, 0, 0, 0.7)',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            zIndex: 1,
                        }}
                    >
                        <Paper
                            sx={{
                                p: 3,
                                textAlign: 'center',
                                maxWidth: '80%',
                            }}
                        >
                            <Typography variant="h6" gutterBottom>
                                Recurso Exclusivo
                            </Typography>
                            <Typography>
                                A inclusão de código para execução automática está disponível apenas para assinantes.
                            </Typography>
                        </Paper>
                    </Box>
                )}
            </Box>

            <CodeTemplateDialog open={dialogFromTemplate} handleClose={() => { 
                setDialogFromTemplate(false)
                setSelectedFromTemplate(true);
            }} setCode={onChange} />
        </>
    );
}