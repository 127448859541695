import { AccountTree, AddTask, Analytics, Assignment, AssignmentInd, AttachMoney, AvTimer, Build, BuildCircle, Campaign, ChangeCircle, Chat, CrisisAlert, Dashboard, DataUsage, Description, Engineering, FactCheck, Feedback, Folder, Forum, Gavel, Handshake, InsertDriveFile, Inventory, LocalShipping, Person, PersonSearch, PictureAsPdf, PieChart, ReportProblem, RequestQuote, RocketLaunch, Security, Settings, ShoppingCart, SupportAgent, Timeline, TrendingUp, VerifiedUser, Work } from "@mui/icons-material";

export const iconMap: Record<string, React.ComponentType<any>> = {
    Dashboard,
    Assignment,
    Chat,
    AttachMoney,
    ShoppingCart,
    TrendingUp,
    Campaign,
    Person,
    Work,
    AssignmentInd,
    Build,
    Security,
    LocalShipping,
    Inventory,
    Settings,
    Analytics,
    DataUsage,
    PieChart,
    Gavel,
    VerifiedUser,
    Description,
    Folder,
    InsertDriveFile,
    PersonSearch,
    RequestQuote,
    Handshake,
    Timeline,
    RocketLaunch,
    SupportAgent,
    AvTimer,
    Feedback,
    CrisisAlert,
    AddTask,
    BuildCircle,
    Engineering,
    ChangeCircle,
    FactCheck,
    ReportProblem,
    Forum,
    PictureAsPdf,
    AccountTree
};