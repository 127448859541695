import { useTheme } from "@mui/material/styles";
import { Dialog, DialogTitle, DialogContent, IconButton, Box, Typography, Button, TextField, FormControlLabel, Checkbox, Grid, DialogActions, Switch, RadioGroup, Radio } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { DynamicForm, DynamicFormComponentProps } from "..";
import { useForm } from "react-hook-form";
import { Datasource, SelectDataSource } from "./SelectDataSource";
import { DisplayDataSource } from "./DisplayDataSource";
import { DndContext, closestCenter, DragEndEvent } from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import React, { useState, useEffect } from "react";

type EditComponentModalProps = {
    onChange: (component: DynamicFormComponentProps) => void;
    onDelete: (component: DynamicFormComponentProps) => void;
    onClose: () => void;
    component: DynamicFormComponentProps
};

export function EditComponentModal({ onClose, onChange, onDelete, component }: EditComponentModalProps) {

    const { register, setValue, getValues, watch } = useForm<DynamicFormComponentProps>({
        defaultValues: component
    });

    const [focusedIndex, setFocusedIndex] = useState<number | null>(null)

    const handlePaste = (event: React.ClipboardEvent) => {
        const pastedText = event.clipboardData.getData('text');
        if (pastedText && pastedText.includes('\n') && !watch('useDataSource')) {
            event.preventDefault();
            const options = pastedText
                .split('\n')
                .map(line => line.trim())
                .filter(line => line.length > 0);
            setValue('options', options);
        }
    };

    function handleDragEnd(event: DragEndEvent) {
        const { active, over } = event;
        if (!over) return;
        if (active.id !== over.id) {
            const oldIndex = parseInt(active.id.toString(), 10);
            const newIndex = parseInt(over.id.toString(), 10);
            const arr = [...(getValues('options') || [])];
            setValue('options', arrayMove(arr, oldIndex, newIndex));
        }
    }

    const SortableOption = React.memo(({ item, index }: { item: string, index: number }) => {
        const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: String(index) });
        const style: React.CSSProperties = {
            transform: CSS.Translate.toString(transform),
            transition
        };

        const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            const newValue = e.target.value
            const options = [...(getValues('options') || [])]
            options[index] = newValue
            setValue('options', options)
        }

        const handleRemoveItem = (index: number) => {
            const options = [...(getValues('options') || [])]
            options.splice(index, 1)
            setValue('options', options)

        }

        const handleAddItem = () => {
            const options = [...(getValues('options') || [])]
      
            options.push('');
            setValue('options', options)
        }
        
        const isLastField = (getValues('options') || []).length === index + 1

        return (
            <div ref={setNodeRef} style={style} {...attributes}>
                <Grid container alignItems="center" mb={1}>
                    <Grid item xs={1}>
                        {!isLastField ? (
                            <IconButton {...listeners} aria-label="drag">
                                <DragIndicatorIcon/>
                            </IconButton>
                        ) : (
                            <IconButton disabled>
                                <DragIndicatorIcon/>
                            </IconButton>
                        )}
                    </Grid>
                    <Grid item xs={10}>
                        <TextField 
                            value={item} 
                            onChange={handleChange} 
                            onFocus={() => setFocusedIndex(index)} 
                            inputRef={(input) => {
                                if (focusedIndex === index) {
                                    input?.focus()
                                }
                            }} fullWidth onPaste={handlePaste} 
                        />
                    </Grid>
                    <Grid item xs={1}>
                        {
                            isLastField ? (
                                <IconButton aria-label="add" onClick={handleAddItem}>
                                    <AddIcon />
                                </IconButton>
                            ) : (
                                <IconButton aria-label="delete" onClick={() => handleRemoveItem(index)}>
                                    <DeleteIcon />
                                </IconButton>
                            )
                        }
                    </Grid>
                </Grid>
            </div>
        );
    });

    return (
        <Dialog maxWidth="xs" fullWidth open={true} onClose={onClose}>
            <DialogTitle variant='h6'>
                Editar {component.type}
            </DialogTitle>
            <DialogContent>

                <TextField
                    {...register('name')}
                    label="Nome"
                    fullWidth
                    margin="normal"
                />

                {
                    component.type !== 'group' ? (
                        <FormControlLabel
                            control={<Checkbox checked={watch('isRequired')} onChange={(e) => { setValue('isRequired', e.target.checked) }} />}
                            label="Campo obrigatório"
                        />
                    ) : (
                        <DynamicForm
                            values={watch('components') || []}
                            allowUsingRegisteredForm={false}
                            allowPreview={false}
                            onChange={(components: DynamicFormComponentProps[]) => {
                                setValue('components', components);
                            }}
                        />
                    )
                }

                {
                    component.type === "text" && (
                        <Box mt={2}>
                            <Typography variant="h6">Máscara</Typography>
                            <RadioGroup
                                name="mask"
                                value={watch("mask") || "none"}
                                onChange={(e) => setValue("mask", e.target.value)}
                            >
                                <FormControlLabel value="none" control={<Radio />} label="Nenhuma" />
                                <FormControlLabel value="cpf" control={<Radio />} label="CPF" />
                                <FormControlLabel value="cnpj" control={<Radio />} label="CNPJ" />
                                <FormControlLabel value="cpfCnpj" control={<Radio />} label="CPF/CNPJ" />
                                <FormControlLabel value="phone" control={<Radio />} label="Telefone" />
                            </RadioGroup>
                        </Box>
                    )
                }

                {
                    ['select', 'radio', 'checkbox'].includes(component.type) &&
                    <>
                        <Box display="flex" alignItems="center" justifyContent="space-between" mt={2}>
                            <Typography variant="h6">Opções</Typography>
                            {component.type === 'select' && (
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={Boolean(watch('useDataSource'))}
                                            onChange={(e) => {
                                                setValue('useDataSource', e.target.checked);
                                            }}
                                        />
                                    }
                                    label="Usar fonte de dados?"
                                />
                            )}
                        </Box>
                        {
                            watch('useDataSource') ? (
                                (watch('dataSource')) ? (
                                    <DisplayDataSource 
                                        uuid={watch('dataSource') || ''} 
                                        onEdit={() => setValue('dataSource', null)} 
                                    />
                                ) : (
                                    <SelectDataSource 
                                        onDatasourceSelect={(datasource: Datasource | null) => {
                                            setValue('dataSource', datasource?.uuid || null);
                                        }}
                                    />
                                )
                            ) : (
                                <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
                                    <SortableContext items={(getValues('options') || []).map((_, index) => String(index))} strategy={verticalListSortingStrategy}>
                                        {(watch('options') || ['']).map((item: string, index: any) => (
                                            <SortableOption key={index} item={item} index={index} />
                                        ))}
                                    </SortableContext>
                                </DndContext>
                            )
                        }
                    </>
                }
            </DialogContent>
            <DialogActions >
                <Button color="error" variant="outlined" onClick={() => onDelete(component)}>
                    Remover
                </Button>
                <Button  variant="contained" onClick={() => onChange(getValues())}>
                    Salvar
                </Button>
            </DialogActions>
        </Dialog>
    );
}