import { Panel } from "../../../../screens/Settings/Flows/Detail/PropertiesView/Panel";

/**
 * This class adds custom properties to the properties panel for BPMN elements.
 * Specifically, for bpmn:Task elements, it adds a tab with custom properties like deadline,
 * perform task, status, attachment, seem, and advance automatically, allowing users to edit
 * these properties directly from the properties panel.
 */

export function CustomPropertiesProvider(propertiesPanel) {
  propertiesPanel.registerProvider(1000, this);

  this.getTabs = function(element) {
    if (element.type === 'bpmn:Task' || element.type === 'bpmn:SendTask') {

      const baseGroup = {
        id: 'custom-properties-group',
        label: 'Properties',
        entries: [
          {
            id: 'deadline-property',
            component: Panel,
            modelProperty: 'deadline'
          },
          {
            id: 'perform-task-property',
            component: Panel,
            modelProperty: 'performTask'
          },
          {
            id: 'status-property',
            component: Panel,
            modelProperty: 'status'
          },
          {
            id: 'has-attachment-property',
            component: Panel,
            modelProperty: 'hasAttachment'
          },
          {
            id: 'has-observation-property',
            component: Panel,
            modelProperty: 'hasObservation'
          },
          {
            id: 'advance-automatically-property',
            component: Panel,
            modelProperty: 'advanceAutomatically'
          }
        ]
      };

      let additionalEmailGroup = null;

      if (element.type === 'bpmn:SendTask') {
        additionalEmailGroup = {
          id: 'email-properties-group',
          label: 'Email',
          entries: [
            {
              id: 'email-to-property',
              component: Panel,
              modelProperty: 'emailTo'
            },
            {
              id: 'email-subject-property',
              component: Panel,
              modelProperty: 'emailSubject'
            },
            {
              id: 'email-body-property',
              component: Panel,
              modelProperty: 'emailBody'
            }
          ]
        };
      }

      const groups = [ baseGroup ];
      if (additionalEmailGroup) {
        groups.push(additionalEmailGroup);
      }

      return [
        {
          id: 'custom-properties',
          label: 'Custom Properties',
          groups
        }
      ];
    }
  };
}

CustomPropertiesProvider.$inject = ['propertiesPanel'];