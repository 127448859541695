const commonProperties = [
  { name: 'resolutionFormat', isAttr: true, type: 'String' },
  { name: 'performTask', isAttr: true, type: 'String' },
  { name: 'hasObservation', isAttr: true, type: 'String' },
  { name: 'hasAttachment', isAttr: true, type: 'String' },
  { name: 'hasForm', isAttr: true, type: 'String' },
  { name: 'formComponents', isAttr: true, type: 'String' },
  { name: 'hasDeadline', isAttr: true, type: 'String' },
  { name: 'deadline', isAttr: true, type: 'String' },
  { name: 'allowOtherProfilesToView', isAttr: true, type: 'String' }
];

export const customModdle = {
  name: 'custom',
  prefix: 'custom',
  uri: 'https://lite.checar.com/custom',
  xml: {
    tagAlias: 'lowerCase'
  },
  types: [
    {
      name: 'CustomStartEvent',
      extends: ['bpmn:StartEvent'],
      properties: [
        { name: 'hasAttachment', isAttr: true, type: 'String' },
        { name: 'hasObservation', isAttr: true, type: 'String' },
        { name: 'hasForm', isAttr: true, type: 'String' },
        { name: 'formComponents', isAttr: true, type: 'String' },
        { name: 'isPublic', isAttr: true, type: 'String' }
      ]
    },
    {
      name: 'CustomTask',
      extends: ['bpmn:Task'],
      properties: [...commonProperties]
    },
    {
      name: 'CustomServiceTask',
      extends: ['bpmn:ServiceTask'],
      properties: [
        { name: 'embedCode', isAttr: true, type: 'String' }
      ],
    },
    {
      name: 'CustomExclusiveGateway',
      extends: ['bpmn:ExclusiveGateway'],
      properties: [...commonProperties]
    },
    {
      name: 'CustomSendTask',
      extends: ['bpmn:SendTask'],
      properties: [
        { name: 'emailTo', isAttr: true, type: 'String' },
        { name: 'emailSubject', isAttr: true, type: 'String' },
        { name: 'emailBody', isAttr: true, type: 'String' }
      ]
    },
    {
      name: 'CustomEndEvent',
      extends: ['bpmn:EndEvent'],
      properties: [...commonProperties]
    },
  ]
};