import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Card,
    CardActionArea,
    CardContent,
    Typography,
    useTheme,
    Stack,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Divider,
    Box,
    TextField,
    InputAdornment,
    Paper,
    IconButton,
    useMediaQuery
} from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import SearchIcon from '@mui/icons-material/Search';
import { Description } from "@mui/icons-material";
import useDebounce from "../../../../../../hooks/useDebounce";
import axiosClient from "../../../../../../libs/axios";
import { iconMap } from "../../../../../../utils/iconMap";

interface Template {
    id: number;
    name: string;
    category: string;
    description: string;
    type: string;
    spec: string | null;
    code: string | null;
    created_at: string;
    updated_at: string;
    icon: {
        name: string;
        color: string;
    };
    categories: Category[];
}

interface Category {
    id: number;
    name: string;
    created_at: string;
    updated_at: string;
    pivot: {
        template_id: number;
        category_id: number;
    };
}

type Inputs = {
    name: string | null;
    tempGroupName: string | null;
    groups: Array<string>;
    description: string | null;
    icon_name: string | null;
    icon_color: string | null;
};

interface CodeTemplateDialogProps {
    open: boolean;
    handleClose: () => void;
    setCode: (code: string) => void
}

type Group = {
    id: number | null;
    name: string | null;
}

export default function CodeTemplateDialog({
    open,
    handleClose,
    setCode
}: CodeTemplateDialogProps) {
    const theme = useTheme();
    const navigate = useNavigate();

    const [searchTerm, setSearchTerm] = useState("");
    const debouncedSearch = useDebounce(searchTerm, 500);

    const { handleSubmit, watch } = useForm<Inputs>({
        defaultValues: {
            groups: [],
        }
    });

    const queryClient = useQueryClient();

    const { data: existingGroupsData, isLoading: loadingGroups, error: groupsError } = useQuery<Group[]>(
        ['existing-groups'],
        () => axiosClient.get("/group").then(res => res.data.data),
    );

    const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
    const [selectedTemplate, setSelectedTemplate] = useState<Template | null>(null);

    const { data: categoriesData, isLoading: loadingCategories, error: categoriesError } = useQuery<Category[]>(
        ['code-categories'],
        () => axiosClient.get("/category", { params: { type: "code" } }).then(res => res.data),
        {
            enabled: open,
        }
    );

    const { data: searchTemplateData, isLoading: loadingSearchTemplate, error: searchTemplateError, refetch } = useQuery<{
        current_page: number;
        data: Template[];
    }>(
        ['search-code-templates', selectedCategory, debouncedSearch],
        () => {
            let url = "/template";
            if (selectedCategory) {
                url += `?category=${encodeURIComponent(selectedCategory)}`;
            }
            return axiosClient.get(url, { params: { keyword: debouncedSearch, type: "code" } }).then(res => res.data);
        },
        {
            enabled: open,
        }
    );

    console.log("templates de código", searchTemplateData)

    const { mutate: createFlow, isLoading: isCreating } = useMutation(
        (inputs: any) => axiosClient.post("/flow/from-template", inputs),
        {
            onSuccess: (response: any) => {
                enqueueSnackbar("Fluxo criado com sucesso!");
                navigate("/flow/" + response.data.id);
                queryClient.invalidateQueries('flows');
                handleClose();
            },
            onError: (error: any) => {
                const message = error.response?.data?.message || "Erro ao criar o fluxo. Tente novamente.";
                enqueueSnackbar(message, { variant: "error" });
            }
        }
    );

    const onSubmit = (data: any) => {
        console.log(selectedTemplate)
        setCode(selectedTemplate?.code as string);
        handleClose();
    };

    const handleCardClick = (template: Template) => {
        setSelectedTemplate(template);
    };

    return (
        <Dialog onClose={handleClose} open={open} fullWidth maxWidth="lg">
            <DialogTitle>Selecione um template</DialogTitle>
            <DialogContent dividers sx={{ minHeight: "400px" }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={3}>
                        <TextField
                            placeholder="Pesquisar"
                            variant="outlined"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon color="action" />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        {loadingCategories && (
                            <CircularProgress size={24} />
                        )}
                        {!!categoriesError && (
                            <Typography color="error">Erro ao carregar categorias.</Typography>
                        )}
                        {!loadingCategories && categoriesData && (
                            <Box
                                sx={{
                                    maxHeight: '400px',
                                    overflow: 'auto'
                                }}
                            >
                                <List component="nav">
                                    <ListItem disablePadding>
                                        <ListItemButton
                                            selected={selectedCategory === null}
                                            onClick={() => setSelectedCategory(null)}
                                        >
                                            <ListItemText primary="Todas" />
                                        </ListItemButton>
                                    </ListItem>
                                    <Divider />
                                    {categoriesData.map((category) => (
                                        <ListItem key={category.id} disablePadding>
                                            <ListItemButton
                                                selected={selectedCategory === category.name}
                                                onClick={() => setSelectedCategory(category.name)}
                                            >
                                                <ListItemText primary={category.name} />
                                            </ListItemButton>
                                        </ListItem>
                                    ))}
                                </List>
                            </Box>
                        )}
                    </Grid>

                    <Grid item xs={12} md={9}>
                        <Box
                            sx={{
                                maxHeight: '500px',
                                overflowY: 'auto',
                                paddingRight: '8px'
                            }}
                        >
                            {loadingSearchTemplate && (
                                <Grid container justifyContent="center" alignItems="center" style={{ minHeight: '200px' }}>
                                    <CircularProgress />
                                </Grid>
                            )}
                            {!!searchTemplateError && (
                                <Typography color="error">Erro ao carregar templates. Tente novamente.</Typography>
                            )}
                            {!loadingSearchTemplate && searchTemplateData && searchTemplateData.data && searchTemplateData.data.length > 0 && (
                                <Stack direction="row" flexWrap="wrap">
                                    {searchTemplateData.data.map((template: Template) => {
                                        const IconComponent = iconMap[template.icon.name] || Description;

                                        const isSelected = selectedTemplate?.id === template.id;

                                        return (
                                            <Paper
                                                elevation={isSelected ? 4 : 1}
                                                key={template.id}
                                                sx={{
                                                    boxSizing: "border-box",
                                                    width: "100%",
                                                    maxWidth: "240px",
                                                    marginRight: 2,
                                                    marginBottom: 2,
                                                    border: `2px solid transparent`,
                                                    borderColor: isSelected ? theme.palette.primary.main : "none",
                                                    backgroundColor: isSelected ? theme.palette.action.selected : 'inherit',
                                                    cursor: "pointer",
                                                    transition: "border 0.3s, background-color 0.3s",
                                                    ":hover": {
                                                        borderColor:theme.palette.primary.main,
                                                    },
                                                    zIndex: 1,
                                                }}
                                                onClick={() => handleCardClick(template)}
                                            >
                                                <CardContent sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                                    <IconButton>
                                                        <IconComponent style={{ color: template.icon.color }} fontSize="large" />
                                                    </IconButton>
                                                    <Typography variant="h6" gutterBottom>
                                                        {template.name}
                                                    </Typography>
                                                    <Typography variant="body2" color="textSecondary" gutterBottom>
                                                        {template.description.length > 100
                                                            ? `${template.description.substring(0, 100)}...`
                                                            : template.description}
                                                    </Typography>
                                                    {template.categories.length > 0 && (
                                                        <Typography variant="caption" color="textSecondary" alignSelf="flex-end" marginTop={1}>
                                                            {template.categories.map((cat: any) => cat.name).join(', ')}
                                                        </Typography>
                                                    )}
                                                </CardContent>
                                            </Paper>
                                        )
                                    })}
                                </Stack>
                            )}
                            {!loadingSearchTemplate && searchTemplateData && searchTemplateData.data && searchTemplateData.data.length === 0 && (
                                <Typography>Nenhum template encontrado para esta categoria.</Typography>
                            )}
                        </Box>
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose}>
                    Fechar
                </Button>
                <Button
                    onClick={handleSubmit(onSubmit)}
                    variant="contained"
                    disabled={!selectedTemplate || isCreating}
                >
                    {isCreating && <CircularProgress size={14} style={{ marginRight: 8 }} />} Utilizar Código
                </Button>
            </DialogActions>
        </Dialog>
    );
}