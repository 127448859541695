/**
 * This class customizes the elements that appear when a specific element is selected.
**/

export class CustomContextPadProvider {
  constructor(contextPad) {
    contextPad.registerProvider(this);
  }

  getContextPadEntries(element) {
    return function(entries) {
      const newEntries = {};

      if (
        element.type === 'bpmn:Task' || 
        element.type === 'bpmn:EndEvent' ||
        element.type === 'bpmn:ExclusiveGateway' ||
        element.type === 'bpmn:ServiceTask' ||
        element.type === 'bpmn:SendTask'
      ) {
        if (entries['delete']) {
          newEntries['delete'] = {
            ...entries['delete'],
            title: 'Deletar'
          };
        }
        if (entries['connect']) {
          newEntries['connect'] = {
            ...entries['connect'],
            title: 'Conectar'
          };
        }
      } else if (element.type === 'bpmn:StartEvent') {
        if (entries['connect']) {
          newEntries['connect'] = {
            ...entries['connect'],
            title: 'Conectar'
          };
        }
      } else if (element.type === 'bpmn:Lane' && element.businessObject.name === '🔒 Solicitante') {
        // Remover todas as opções para a raia "Solicitante"
        return {};
      } else {
        Object.assign(newEntries, entries);
      }

      return newEntries;
    };
  }
}

CustomContextPadProvider.$inject = ["contextPad"];