import { useState, useEffect, ReactElement } from 'react';
import { Box, Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button, Autocomplete } from '@mui/material';

import Modeler from 'bpmn-js/lib/Modeler';
import AddIcon from "@mui/icons-material/Add";
import { ModdleElement as BpmnElement } from 'bpmn-js/lib/BaseModeler';

import { Panel } from './Panel';
import { is } from 'bpmn-js/lib/util/ModelUtil';
import axiosClient from '../../../../../libs/axios';
import { useQuery } from 'react-query';

interface PropertiesViewProps {
  modeler: Modeler | null;
  modelerReady: boolean;
  user: string | null
}

export function PropertiesView({ modeler, modelerReady, user }: PropertiesViewProps): ReactElement {
  const [selectedElement, setSelectedElement] = useState<BpmnElement | null>(null);
  const [showPanel, setShowPanel] = useState(false);

  const [isLaneModalOpen, setIsLaneModalOpen] = useState(false);
  const [createdLane, setCreatedLane] = useState<any>(null);
  const [groupName, setGroupName] = useState("");

  
  const { data: searchGroups, isLoading: loadingSearchGroups } = useQuery({
    queryKey: ["search-groups"],
    queryFn: () => axiosClient.get("/group?limit=1000"),
    enabled: !user
  });

  const {data: searchGroupsForTemplate, isLoading: isLoadingSearchGroupsForTemplate} = useQuery({
    queryKey: ['public-search-groups'],
    queryFn: () => axiosClient.get("/public/search-groups?limit=1000", {
      params: {
        user: user
      }
    }),
    enabled: !!user
  
  })

  useEffect(() => {
    if (!modelerReady || !modeler) return;

    const eventBus = modeler.get("eventBus") as any;

    const handleShapeCreate = (event: any) => {
      const { shape } = event.context;
      if (is(shape, "bpmn:Lane")) {
        setCreatedLane(shape);
        setIsLaneModalOpen(true);
      }
    };

    eventBus.on("commandStack.shape.create.postExecute", handleShapeCreate);

    return () => {
      eventBus.off("commandStack.shape.create.postExecute", handleShapeCreate);
    };
  }, [modeler, modelerReady]);

  useEffect(() => {
    if (!modelerReady || !modeler) return;

    const elementsThatOpenPanel = [
      'bpmn:StartEvent',
      'bpmn:Task',
      'bpmn:ServiceTask',
      'bpmn:ExclusiveGateway',
      'bpmn:SendTask',
      'bpmn:EndEvent',
    ];

    const handleDoubleClick = (event: { element: BpmnElement }) => {
      const { element } = event;

      if (elementsThatOpenPanel.includes(element.type)) {
        setSelectedElement(element);
        setShowPanel(true);
      } else {
        setSelectedElement(null);
        setShowPanel(false);
      }
    };

    const handleShapeCreate = (event: any) => {
      const { shape } = event.context;
      if (is(shape, 'bpmn:Lane')) {
        setCreatedLane(shape);
        setIsLaneModalOpen(true);
      }
    };

    const eventBus = modeler.get('eventBus') as any;

    eventBus.on('element.dblclick', handleDoubleClick);

    eventBus.on('element.dblclick', handleDoubleClick);
    eventBus.on('commandStack.shape.create.postExecute', handleShapeCreate);

    return () => {
      eventBus.off('element.dblclick', handleDoubleClick);
      eventBus.off('commandStack.shape.create.postExecute', handleShapeCreate);
    };
  }, [modeler, modelerReady]);

  const handleConfirmLane = () => {
    if (!modeler || !createdLane) return;

    const modeling = modeler.get("modeling") as any;

    modeling.updateProperties(createdLane, {
      name: groupName
    });

    setIsLaneModalOpen(false);
    setCreatedLane(null);
    setGroupName("");
  };

  const handleCloseLaneModal = () => { 
    if (!modeler || !createdLane) { 
      setIsLaneModalOpen(false); return; 
    } 
    if (!groupName.trim()) { 
      const modeling = modeler.get("modeling") as any; 
      modeling.removeElements([ createdLane ]); 
    } 
    setIsLaneModalOpen(false); 
    setCreatedLane(null); 
    setGroupName("");
  };

  return (
    <Box>
      {selectedElement && showPanel && (
        <Panel
          open={showPanel}
          onClose={() => setShowPanel(false)}
          modeler={modeler}
          element={selectedElement}
        />
      )}

<Dialog open={isLaneModalOpen} onClose={handleCloseLaneModal}>
        <DialogTitle>Adicionar Raia</DialogTitle>
        <DialogContent>
          <Box 
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="row"
            gap={4}
          >
            <Autocomplete
              freeSolo
              id="group"
              loading={loadingSearchGroups ? loadingSearchGroups : isLoadingSearchGroupsForTemplate}
              disableClearable
              options={
                searchGroups?.data ? searchGroups?.data?.data?.map((group: any) => group.name) || [] : 
                searchGroupsForTemplate?.data?.data?.map((group:any) => group.name) || [] 
              }
              onChange={(event, value: string) => setGroupName(value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  margin="normal"
                  label="Nome do perfil"
                  helperText="Ex.: Analista de Crédito, Gerente de Compras etc."
                  onChange={(e) => setGroupName(e.target.value)}
                  InputProps={{
                    ...params.InputProps,
                    type: 'search'
                  }}
                />
              )}
            />
            <Button
              onClick={handleConfirmLane}
              variant="contained"
              disabled={groupName.trim() === ""}
            >
              <AddIcon />
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
}