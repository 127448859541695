import { Box, Button, Typography, FormControl, Autocomplete, TextField, CircularProgress } from "@mui/material";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useState } from "react";
import { useQuery } from "react-query";
import axiosClient from "../../../libs/axios";
import useDebounce from "../../../hooks/useDebounce";
import { formatDate } from "../../../utils/formatDate";

export type Datasource = {
    uuid: string;
    name: string;
    file_url: string;
    file_type: string;
    created_at: string;
    created_by: string;
}

type SelectDataSourceProps = {
    onDatasourceSelect?: (datasource: Datasource | null) => void;
}

export function SelectDataSource({ onDatasourceSelect }: SelectDataSourceProps) {
    const [selectedDatasource, setSelectedDatasource] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [open, setOpen] = useState(false);
    const [isUploading, setIsUploading] = useState(false);

    const debouncedSearchTerm = useDebounce(searchTerm, 500);

    const { data: datasourcesData, isLoading, refetch } = useQuery({
        queryKey: ["fetch-data-source", debouncedSearchTerm],
        queryFn: () => axiosClient.get("/data-source", {
            params: {
                keyword: debouncedSearchTerm
            }
        }),
        cacheTime: 0,
        keepPreviousData: true,
        staleTime: 5000,
    });

    const datasources: Datasource[] = datasourcesData?.data?.data || [];

    const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (!file) return;

        const formData = new FormData();
        formData.append('file', file);

        setIsUploading(true);
        try {
            await axiosClient.post('/data-source', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            await refetch();
        } catch (error) {
            console.error('Erro ao fazer upload:', error);
        } finally {
            setIsUploading(false);
            event.target.value = '';
        }
    };

    if (isLoading) {
        return <Typography>Carregando...</Typography>;
    }

    if (datasources.length === 0) {
        return (
            <Box sx={{ mt: 2, p: 2, border: '2px dashed', borderColor: 'grey.400', borderRadius: 1, textAlign: 'center' }}>
                <Typography variant="h6" mb={1}>
                    Faça upload de um arquivo .csv
                </Typography>
                <Typography color="text.secondary" mb={2}>
                    Ele vai servir como fonte de dados do seu componente para o usuário escolher uma opção
                </Typography>
                <Button 
                    variant="outlined" 
                    component="label"
                    startIcon={isUploading ? <CircularProgress size={20} /> : <CloudUploadIcon />}
                    disabled={isUploading}
                >
                    {isUploading ? 'Carregando...' : 'Upload .csv'}
                    <input 
                        type="file" 
                        hidden 
                        accept=".csv" 
                        onChange={handleFileUpload} 
                        disabled={isUploading}
                    />
                </Button>
            </Box>
        );
    }

    return (
        <Box sx={{ mt: 2 }}>
            <FormControl fullWidth>
                <Autocomplete
                    disabled={isUploading}
                    open={open}
                    onOpen={() => setOpen(true)}
                    onClose={() => setOpen(false)}
                    value={datasources.find(ds => ds.uuid === selectedDatasource) || null}
                    onChange={(_, newValue) => {
                        setSelectedDatasource(newValue?.uuid || '');
                        onDatasourceSelect?.(newValue || null);
                    }}
                    onInputChange={(_, value, reason) => {
                        if (reason !== 'reset') {
                            setSearchTerm(value);
                        }
                    }}
                    options={datasources}
                    getOptionLabel={(option) => option.name}
                    loading={isLoading}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Selecione uma fonte de dados"
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <>
                                        {isUploading && (
                                            <CircularProgress 
                                                color="inherit" 
                                                size={20} 
                                                sx={{ mr: 2 }}
                                            />
                                        )}
                                        {params.InputProps.endAdornment}
                                    </>
                                ),
                            }}
                        />
                    )}
                    renderOption={(props, option) => (
                        <Box component="li" {...props}>
                            <Box>
                                <Typography variant="caption" display="block" fontWeight="bold">
                                    {option.name}
                                </Typography>
                                <Typography variant="caption" display="block">
                                    por {option.created_by} em {formatDate(option.created_at)}
                                </Typography>
                            </Box>
                        </Box>
                    )}
                />
            </FormControl>

            <Box sx={{ display: 'flex', gap: 0.5, alignItems: 'center' }}>
                <Typography 
                    variant="caption" 
                    sx={{ 
                        cursor: isUploading ? 'not-allowed' : 'pointer',
                        textDecoration: 'underline',
                        color: isUploading ? 'text.disabled' : 'primary.main',
                        '&:hover': {
                            color: isUploading ? 'text.disabled' : 'primary.dark'
                        }
                    }}
                    component="label"
                >
                    Clique aqui
                    <input 
                        type="file" 
                        hidden 
                        accept=".csv" 
                        onChange={handleFileUpload}
                        disabled={isUploading}
                    />
                </Typography>
                <Typography variant="caption">
                    para fazer upload de uma nova fonte de dados .csv
                </Typography>
            </Box>
        </Box>
    );
} 