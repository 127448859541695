import { AppBar, Box, Chip, CircularProgress, Container, Dialog, DialogContent, Divider, Grid, Link, Step, StepContent, StepLabel, Stepper, Toolbar, Typography, Radio, RadioGroup, FormControlLabel, Stack, Button, DialogActions, DialogTitle, Paper } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import PrintIcon from '@mui/icons-material/Print';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from "react-query";
import axiosClient from "../../../libs/axios";
import { useContext, useState } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import { ResolveTaskForm } from "./ResolveTaskForm";
import { formatDate } from "../../../utils/formatDate";
import { enqueueSnackbar } from "notistack";
import { situationLabel } from "../../../utils/situationLabel";
import { situationColor } from "../../../utils/situationColor";
import { ViewFlow } from "../../../components/ViewFlow";
import { DynamicFormRenderResponse } from "../../../components/DynamicForm/DynamicFormRenderResponse";
import { DynamicFormComponentProps } from "../../../components/DynamicForm";
import { formatExpiresAt } from "../../../utils/formatExpiresAt";
import { useNavigate, useParams } from "react-router-dom";
import { CoPresent, Delete, InsertLink, Share } from "@mui/icons-material";
import useDebounce from "../../../hooks/useDebounce";
import { EmailRecipientsSelector } from "./EmailRecipientSelector";

export function ProcessDetail() {
    const { getUserData } = useContext(AuthContext);
    const { code } = useParams();
    const navigate = useNavigate();

    const [showGuestsDialog, setShowGuestsDialog] = useState<boolean>(false);
    const [emails, setEmails] = useState<any[]>([]);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);

    const queryClient = useQueryClient();
    const [searchTerm, setSearchTerm] = useState('');
    const debouncedSearchTerm = useDebounce(searchTerm, 500);
    const [loading, setLoading] = useState<boolean>(false);

    const {
        data: usersInfiniteData,
        fetchNextPage: fetchUsersNextPage,
        hasNextPage: hasUsersNextPage,
        isFetchingNextPage: isFetchingUsersNextPage,
    } = useInfiniteQuery(
        ["fetch-all-users", debouncedSearchTerm],
        async ({ pageParam = 1 }) => {
            setLoading(true);
            const response = await axiosClient.get(`/user?page=${pageParam}`, { params: { keyword: debouncedSearchTerm } });
            setLoading(false);
            return response.data; 
        },
        {
            getNextPageParam: (lastPage) => lastPage.next_page_url ? lastPage.current_page + 1 : undefined,
        }
    );

    const allUsers = usersInfiniteData?.pages.flatMap(page => page.data) ?? [];
    const { data, isLoading } = useQuery({
        queryKey: ["process", code],
        queryFn: () => axiosClient.get("/process/" + code),
        keepPreviousData: true,
        cacheTime: 0,
    });
    
    let userData = getUserData();
    const process = data?.data;

    const { data: processViewersData, isLoading: isProcessViewersLoading, refetch: refetchProcessViewers } = useQuery({
        queryKey: ["process_viewers", process?.id],
        queryFn: () => axiosClient.get(`/process-viewer/search?process_id=${process.id}`).then(res => res.data),
        enabled: !!process?.id,
        onSuccess: (data) => {
            setEmails(data)
        },
    });

    const { mutate: printProcess } = useMutation(() =>
        axiosClient.post(`process/${code}/print`, {}, { responseType: 'arraybuffer' }),
        {
            onSuccess: (response) => {
                const blob = new Blob([response.data], { type: 'application/pdf' });
                const url = window.URL.createObjectURL(blob);
                window.open(url);
                enqueueSnackbar('Impresso com sucesso', { variant: 'success' });
            },
            onError: () => {
                enqueueSnackbar('Erro ao imprimir', { variant: 'error' });
            }
        }
    );

    const onDelete = async () => {
        setIsDeleting(true);
        try {
            if (userData?.is_admin) {
                await axiosClient.delete(`/process/` + process.id);
                enqueueSnackbar("Processo excluído com sucesso", { variant: "success" });
                navigate('/tasks');
                queryClient.invalidateQueries(['search-tasks']);
            }
        } catch (error) {
            enqueueSnackbar("Erro ao excluir processo", { variant: "error" });
        } finally {
            setOpenDeleteDialog(false);
            setIsDeleting(false);
        }
    };

    const handlePrintProcess = async () => {
        printProcess();
    };

    const { mutate: createViewers, isLoading: isCreatingViewers } = useMutation(
        (payload: any) => axiosClient.post('/process-viewer', payload),
        {
        onSuccess: (response) => {
            enqueueSnackbar("Observadores adicionados com sucesso", { variant: "success" });
            setShowGuestsDialog(false);
            refetchProcessViewers();
        },
        onError: () => {
            enqueueSnackbar("Erro ao adicionar observadores", { variant: "error" });
        }
        }
    );

    const handleSaveObservers = () => {
        if (!emails.length) {
            enqueueSnackbar("Nenhum observador selecionado", { variant: "warning" });
            return;
        }
        const viewersPayload = emails.map((item: any) => {
            const viewer: any = {
                email: item.email,
                invited_by: userData?.id,
                process_id: process.id,
            };
            if (!item.isGuest) {
                viewer.user_id = item.user_id;
            }
            return viewer;
        });
        createViewers({ viewers: viewersPayload });
    };

    return (
        <Dialog fullScreen open={true}>
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={() => navigate(-1)} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        Processo #{code}
                    </Typography>
                </Toolbar>
            </AppBar>

            <DialogContent>
                <Container>
                    {isLoading && (
                        <Box display="flex" justifyContent="center" py={3}>
                        <CircularProgress size={40} />
                        </Box>
                    )}

                    {data?.data && (
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={4} md={3}>
                                <Box 
                                    mb={2}
                                    sx={{ position: "sticky", top: "1px", maxHeight: "calc(100vh - 100px)", overflowY: "auto" }}
                                >
                                    <Box mb={2}>
                                        <Box display="flex" alignItems="flex-start" justifyContent="space-between">
                                        <Box flex={1} mr={1}>
                                            <Typography variant="caption">Fluxo</Typography>
                                            <Typography variant="h6" sx={{ wordBreak: 'break-word' }}>
                                            {data.data.flow.name}
                                            </Typography>
                                        </Box>
                                        <ViewFlow flowId={data.data.flow.id} version={data.data.flow_version} nameFlow={data.data.flow.name}/>
                                        </Box>
                                        <Box mt={1}>
                                        <Chip label={`Versão: ${data.data.flow_version}`} size="small"/>
                                        </Box>
                                    </Box>
                                    <Box mb={2}>
                                        <Typography variant="caption">Criado por</Typography>
                                        <Typography variant="h6">{data.data.user?.name || 'Anônimo'}</Typography>
                                    </Box>
                                    <Box mb={2}>
                                        <Typography variant="caption">Data de criação</Typography>
                                        <Typography variant="h6">{formatDate(data.data.created_at)}</Typography>
                                    </Box>
                                    <Box mb={6} display="flex" flexDirection="column" alignItems="flex-start">
                                        <Typography variant="caption">Situação</Typography>
                                        <Chip sx={{ fontSize: "14px" }} label={situationLabel(data.data.situation)} color={situationColor(data.data.situation)} />
                                    </Box>

                                </Box>
                            </Grid>

                            <Grid item display={'flex'} justifyContent={'center'} xs={0} sm={1}>
                                <Divider sx={{ width: 2 }} orientation="vertical" />
                            </Grid>

                            <Grid item xs={12} sm={7} md={8}>
                                <Typography variant="h5" mb={2}>Tarefas</Typography>
                                <Stepper activeStep={data.data.tasks.length - 1} orientation="vertical">
                                    {data.data?.tasks?.map((i: any, index: number) => (
                                        <Step key={index} expanded completed={i.type === 'Start' || i.resolved_at}>
                                            <StepLabel
                                                icon={
                                                    index === 0 && !i.resolved_at ? (
                                                        <Typography
                                                            style={{
                                                                backgroundColor: '#0d47a1',
                                                                color: 'white',
                                                                width: 27,
                                                                height: 27,
                                                                borderRadius: '50%',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center'
                                                            }}
                                                        >
                                                            {data.data.tasks.length}
                                                        </Typography>
                                                    ) : ''
                                                }
                                                optional={
                                                <>
                                                    {i.resolved_at ? (
                                                        <>
                                                            <Typography variant="caption" component="div">
                                                                {i.type === 'End' ? 'Finalizado' : (i.type === 'Start' ? 'Iniciado' : 'Resolvido')} em {formatDate(i.resolved_at)}
                                                            </Typography>
                                                            {i.resolved_by && (
                                                                <Typography variant="caption" component="div">
                                                                    por {i.resolved_by.name}{i.group ? ` / ${i.group.name}` : ""}
                                                                </Typography>
                                                            )}
                                                        </>
                                                    ) : (
                                                        <>
                                                            <Typography variant="caption" component="div">
                                                                Em andamento{i.group ? ` / ${i.group.name}` : " / Solicitante"}
                                                            </Typography>
                                                            {i.expires_at && (
                                                                <Typography variant="caption" component="div">
                                                                    Expira em {formatExpiresAt(i.expires_at)}
                                                                </Typography>
                                                            )}
                                                        </>
                                                    )}
                                                </>
                                                }
                                            >
                                                <Typography variant="body1">
                                                    {i.type === 'End' ? 'Fim' : i?.title}
                                                </Typography>
                                            </StepLabel>
                                            <StepContent>
                                                {i.resolved_at && (
                                                <Box>
                                                    {i.type === 'Conditional' && (
                                                        <RadioGroup row value={i.chosen} sx={{ mb: 1 }}>
                                                            {i.options.map((option: string) => (
                                                                <FormControlLabel
                                                                    key={option}
                                                                    value={option}
                                                                    control={<Radio disabled />}
                                                                    label={option}
                                                                    disabled
                                                                />
                                                            ))}
                                                        </RadioGroup>
                                                    )}
                                                    {(i.form && i.form_response) && (
                                                    <DynamicFormRenderResponse
                                                        alwaysExpanded={true}
                                                        form={i.form as DynamicFormComponentProps[]}
                                                        formResponse={i.form_response}
                                                        files={i.files}
                                                    />
                                                    )}
                                                    {i.observation && (
                                                    <Typography variant="caption" component="div" my={0.5}>
                                                        <b>Observações</b>: {i.observation}
                                                    </Typography>
                                                    )}
                                                    {i.files && i.files.filter((item: any) => !item.form_response_ref).map((item: any) => (
                                                    <Box key={item.id} display="flex" gap={1} alignItems="center">
                                                        <AttachFileIcon sx={{ fontSize: 14 }} />
                                                        <Link target="_blank" variant="caption" href={item.path}>
                                                        {item.name}
                                                        </Link>
                                                    </Box>
                                                    ))}
                                                </Box>
                                                )}
                                                {!i.resolved_at && ((i.group?.id === userData?.group?.id) || (i.user?.id === userData?.id && !i.group) || userData?.is_admin) && (
                                                <ResolveTaskForm onClose={() => {}} code={code || ""} task={i} />
                                                )}
                                            </StepContent>
                                        </Step>
                                    ))}
                                </Stepper>
                            </Grid>
                        </Grid>
                    )}
                </Container>
            </DialogContent>

            <Dialog open={openDeleteDialog} onClose={() => setOpenDeleteDialog(false)} fullWidth>
                <DialogTitle>Excluir Processo</DialogTitle>
                <DialogContent>
                    Deseja realmente excluir este processo?
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDeleteDialog(false)} disabled={isDeleting}>Cancelar</Button>
                    <Button onClick={onDelete} color="primary" variant="contained" disabled={isDeleting}>
                        {isDeleting ? <CircularProgress size={24} /> : "Excluir"}
                    </Button>
                </DialogActions>
            </Dialog>

            {process && (
                <Dialog open={showGuestsDialog} onClose={() => setShowGuestsDialog(false)} fullWidth>
                    <DialogTitle>Gerenciar Observadores</DialogTitle>
                    <DialogContent>
                        <EmailRecipientsSelector
                            users={allUsers as any}
                            value={emails}
                            onChange={setEmails}
                            fetchMore={fetchUsersNextPage}
                            hasNextPage={true}
                            isFetchingNextPage={false}
                            onUserSearchChange={setSearchTerm}
                            viewers={processViewersData}
                            processId={process.id}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setShowGuestsDialog(false)} disabled={isDeleting || isCreatingViewers}>
                            Cancelar
                        </Button>
                        <Button onClick={handleSaveObservers} color="primary" variant="contained" disabled={isDeleting || isCreatingViewers}>
                            {isCreatingViewers ? <CircularProgress size={24} /> : "Salvar"}
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </Dialog>
    );
}
