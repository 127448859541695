import { useTheme } from "@mui/material/styles";
import { Dialog, DialogTitle, DialogContent, IconButton, Box, Card, CardContent, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { v4 as uuidv4 } from 'uuid';
import React from "react";
import { DynamicFormComponentProps } from "..";
import TextFieldsIcon from '@mui/icons-material/TextFields';
import DialpadIcon from '@mui/icons-material/Dialpad';
import EventIcon from '@mui/icons-material/Event';
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import AppsIcon from '@mui/icons-material/Apps';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import NotesIcon from '@mui/icons-material/Notes';
import LocationOnIcon from '@mui/icons-material/LocationOn';

export const types = [
    { name: 'Campo texto', type: 'text', icon: <TextFieldsIcon /> },
    { name: 'Área de texto', type: 'textarea', icon: <NotesIcon /> },
    { name: 'Campo numérico', type: 'number', icon: <DialpadIcon /> },
    { name: 'Campo data', type: 'date', icon: <EventIcon /> },
    { name: 'Campo monetário', type: 'money', icon: <AttachMoneyIcon /> },
    { name: 'Caixa suspensa', type: 'select', icon: <ArrowDropDownCircleIcon />, options: ['Opção 1', 'Opção 2', 'Opção 3'] },
    { name: 'Seleção única', type: 'radio', icon: <RadioButtonCheckedIcon />, options: ['Opção 1', 'Opção 2', 'Opção 3'] },
    { name: 'Seleção múltipla', type: 'checkbox', icon: <CheckBoxIcon />, options: ['Opção 1', 'Opção 2', 'Opção 3'] },
    { name: 'Upload', type: 'upload', icon: <CloudUploadIcon /> },
    { name: 'Grupo de campos', type: 'group', icon: <AppsIcon /> },
    { name: 'Endereço', type: 'address', icon: <LocationOnIcon /> },
];

type ComponentsModalProps = {
    open: boolean;
    onSelect: (component: DynamicFormComponentProps) => void;
    onClose: () => void;
};

export function ComponentsModal({ open, onClose, onSelect }: ComponentsModalProps) {

    const theme = useTheme();

    return (
            <Dialog maxWidth="xs" open={open} onClose={onClose}>
                <DialogTitle variant='h6' id="customized-dialog-title">
                    Selecionar componente
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{ position: 'absolute', right: 8, top: 8 }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent>
                    <Box display="flex" flexWrap="wrap" justifyContent="center" gap={2}>
                        {types.map((input: any) => {

                            input.id = uuidv4();

                            return (
                                <Card
                                    elevation={0}
                                    key={input.id}
                                    sx={{
                                        width: 100,
                                        height: 100,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        border: `1px solid ${theme.palette.primary.main}`,
                                        backgroundColor: 'transparent',
                                        cursor: 'pointer',
                                        borderRadius: '10px',
                                        '&:hover': { backgroundColor: 'rgba(173, 216, 230, 0.3)' },
                                    }}
                                    onClick={() => onSelect(input)}
                                >
                                    <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        <Box color={theme.palette.primary.main} mt={2} sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            width: 35,
                                            height: 35,
                                            borderRadius: '50%',
                                            border: `1px solid ${theme.palette.primary.main}`,
                                        }}>
                                            {React.cloneElement(input.icon, { fontSize: 'small' })}
                                        </Box>
                                        <Typography align="center" variant="caption" mt={1} lineHeight={1} color={theme.palette.primary.main}>
                                            {input.name}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            )
                        })}
                    </Box>
                </DialogContent>
            </Dialog>
    );
}