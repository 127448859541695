import { useContext, useEffect } from 'react';
import './App.css';
import { ThemeProvider, createTheme } from '@mui/material';
import { QueryClient, QueryClientProvider } from 'react-query';
import { yellow } from '@mui/material/colors';
import { AuthContext } from './contexts/AuthContext';
import { RouterProvider, createBrowserRouter, useParams } from 'react-router-dom';
import publicRoutes from './routes/public';
import { SnackbarProvider } from 'notistack';
import { CompanyProvider } from './contexts/CompanyContext';
import privateRoutes from './routes/private';
import PublicTemplates from './screens/Public/Templates';

const twentyFourHoursInMs = 1000 * 60 * 60 * 24;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: twentyFourHoursInMs,
    },
  },
});

const defaultTheme = createTheme({
  palette: {
    background: {
      default: '#FBFBFB'
    },
    primary: {
      main: '#0b2643',
    },
    secondary: yellow
  }
});

function App() {

  const { isAuth } = useContext(AuthContext);

  const router = createBrowserRouter([
    isAuth ? privateRoutes() : {}, ...publicRoutes(),
  ]);

  const masterRouter = createBrowserRouter([
    { path: "/public/templates/:id", element: <PublicTemplates/>  },
  ]);

  const subdomain = window.location.hostname.split(".")[0];
  const isMaster = subdomain === 'neotaskadmin'
 
  useEffect(() => {
    console.log(subdomain)
    if (subdomain === 'www') {
      window.location.href = 'https://neotask.com.br';
    }
  }, [subdomain]);

  if(subdomain === "www" || !subdomain) return <></>;

  return (
    <SnackbarProvider maxSnack={6} autoHideDuration={8000}>
      <QueryClientProvider client={queryClient}>
            <ThemeProvider theme={defaultTheme}>
              {isMaster ? 
                <RouterProvider router={masterRouter}/>
               : (
                <CompanyProvider>
                  <RouterProvider router={router}/>
                </CompanyProvider>
               )}

            </ThemeProvider>
      </QueryClientProvider>
    </SnackbarProvider>
  );
}

export default App;
