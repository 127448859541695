import inherits from 'inherits';
import BaseRenderer from 'diagram-js/lib/draw/BaseRenderer';
import { is } from 'bpmn-js/lib/util/ModelUtil';

/**
 * This class is responsible for performing some action when the element is already on the screen.
 */

export default function CustomRenderer(eventBus, modeling) {
  BaseRenderer.call(this, eventBus, 1500);

  // Add listener to prevent deletion of "bpmn:StartEvent" element and "Requester" lane
  eventBus.on('commandStack.elements.delete.preExecute', 1500, function(event) {
    const elements = event.context.elements;
    elements.forEach(element => {
      if (is(element, 'bpmn:StartEvent') || (is(element, 'bpmn:Lane') && element.businessObject.id === 'Lane_REQUESTER')) {
        event.preventDefault();
      }
    });
  });

  // Add listener to prevent renaming when double clicking on "bpmn:StartEvent" element and first lane
  eventBus.on('element.dblclick', 1500, function(event) {
    const element = event.element;
    
    if ((is(element, 'bpmn:Lane') && element.businessObject.id === 'Lane_REQUESTER')) {
      event.stopPropagation();
    }
  });

  // Add listener to prevent moving the "bpmn:StartEvent" element
  eventBus.on('drag.end', function(event) {
    const context = event.context;
    const shape = context.shape;
      
    if (is(shape, 'bpmn:StartEvent')) {
      event.stopPropagation();
      event.preventDefault();
    }
  });

  this.canRender = function(element) {
    return is(element, 'bpmn:StartEvent');
  };
}

inherits(CustomRenderer, BaseRenderer);

CustomRenderer.$inject = ['eventBus', 'modeling'];