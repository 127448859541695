import { Box, Typography, IconButton, CircularProgress } from "@mui/material";
import StorageIcon from '@mui/icons-material/Storage';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import DownloadIcon from '@mui/icons-material/Download';
import { useQuery } from "react-query";
import axiosClient from "../../../libs/axios";
import { formatDate } from "../../../utils/formatDate";

type DisplayDataSourceProps = {
    uuid: string;
    onEdit: () => void;
};

export function DisplayDataSource({ uuid, onEdit }: DisplayDataSourceProps) {

    const { data, isLoading } = useQuery({
        queryKey: ['datasource', uuid],
        queryFn: () =>axiosClient.get(`/data-source/${uuid}`),
        enabled: !!uuid
    });

    if (isLoading) return <CircularProgress />

    let datasource = data?.data;

    return (
        <Box mt={2}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <StorageIcon color="primary" fontSize="small" />
                    <Box ml={1} sx={{ maxWidth: '260px' }}>
                        <Typography 
                            variant="caption" 
                            display="block" 
                            fontWeight="bold"
                            sx={{ 
                                wordBreak: 'break-word',
                                whiteSpace: 'normal'
                            }}
                        >
                            {datasource.name}
                        </Typography>
                        <Typography variant="caption" display="block">
                            por {datasource.created_by} em {formatDate(datasource.created_at)}
                        </Typography>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', gap: 1 }}>
                    <IconButton
                        size="small"
                        onClick={() => window.open(datasource.file_url, '_blank')}
                        title="Baixar arquivo"
                    >
                        <DownloadIcon />
                    </IconButton>
                    <IconButton
                        size="small"
                        onClick={onEdit}
                        title="Trocar fonte de dados"
                    >
                        <SwapHorizIcon />
                    </IconButton>
                </Box>
            </Box>
        </Box>
    );
} 