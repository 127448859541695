import React, { useMemo, useState } from "react";
import {
  Autocomplete,
  TextField,
  Box,
  List,
  ListItem,
  ListItemText,
  IconButton,
  CircularProgress,
  ListItemIcon,
  ListItemSecondaryAction,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { AccountCircle, Add, ErrorOutlineRounded, InsertLink } from "@mui/icons-material";
import axiosClient from "../../../../libs/axios";
import { useQueryClient } from "react-query";

interface User {
  id: number;
  name: string;
  email: string;
}

export interface EmailRecipient {
  email: string;
  isGuest: boolean;
  name?: string;
  user_id?: number;
}

interface EmailRecipientsSelectorProps {
  users: User[];
  isFetchingNextPage?: boolean;
  hasNextPage?: boolean;
  fetchMore?: () => void;
  value: EmailRecipient[];
  onChange: (emails: EmailRecipient[]) => void;
  onUserSearchChange?: (search: string) => void;
  viewers: any[];
  processId: number;
}

export const EmailRecipientsSelector: React.FC<EmailRecipientsSelectorProps> = ({
  users,
  isFetchingNextPage,
  hasNextPage,
  fetchMore,
  value,
  onChange,
  onUserSearchChange,
  viewers,
  processId,
}) => {
  const [userInputValue, setUserInputValue] = useState<string>("");
  const [externalEmailInput, setExternalEmailInput] = useState<string>("");

  const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false);
  const [selectedRecipient, setSelectedRecipient] = useState<EmailRecipient | null>(null);

  const queryClient = useQueryClient();

  const filteredUsers = useMemo(() => 
    users.filter(user => 
      !value.some(recipient => 
        recipient.email.toLowerCase() === user.email.toLowerCase()
      )
    )
  , [users, value]);

  const isNewViewer = (viewer: any) => {
    const emails = viewers.map(viewer => viewer.email);
    return !emails.includes(viewer.email);
  };

  const handleUserSelect = (
    _event: React.SyntheticEvent,
    newValue: User | null
  ) => {
    if (newValue) {
      const newRecipients = [
        ...value,
        { email: newValue.email, isGuest: false, name: newValue.name, user_id: newValue.id }
      ];
      onChange(newRecipients);
      setUserInputValue("");
      onUserSearchChange?.("");
    }
  };

  const validateEmail = (email: string) =>
    /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  const handleAddExternalEmail = () => {
    const trimmedEmail = externalEmailInput.trim();
    if (validateEmail(trimmedEmail)) {
      if (!value.some(recipient => recipient.email === trimmedEmail)) {
        const newRecipients = [...value, { email: trimmedEmail, isGuest: true }];
        onChange(newRecipients);
      }
      setExternalEmailInput("");
    }
  };

  const handleDeleteEmail = (emailToDelete: string) => {
    const recipient = value.find(recipient => recipient.email === emailToDelete);
    if (!recipient) return;

    if (isNewViewer(recipient)) {
      onChange(value.filter(r => r.email !== emailToDelete));
    } else {
      setSelectedRecipient(recipient);
      setConfirmDialogOpen(true);
    }
  };

  const handleConfirmDelete = () => {
    if (selectedRecipient) {
      axiosClient
        .put(`/process-viewer/unlink-process?email=${selectedRecipient.email}&process_id=${processId}`)
        .then(() => {
          onChange(value.filter(r => r.email !== selectedRecipient.email));
          setConfirmDialogOpen(false);
          setSelectedRecipient(null);
          queryClient.invalidateQueries(["process_viewers"]);
        })
        .catch((error: any) => {
          console.error("Erro ao desvincular observador:", error);
          setConfirmDialogOpen(false);
          setSelectedRecipient(null);
        });
    }
  };

  const handleCancelDelete = () => {
    setConfirmDialogOpen(false);
    setSelectedRecipient(null);
  };

  return (
    <Box>
      <Autocomplete
        options={filteredUsers}
        getOptionLabel={(option) => `${option.name} (${option.email})`}
        value={null}
        onChange={handleUserSelect}
        inputValue={userInputValue}
        onInputChange={(_event, newValue) => {
          setUserInputValue(newValue);
          onUserSearchChange?.(newValue);
        }}
        filterOptions={(options) => options}
        ListboxProps={{
          sx: { position: 'relative', maxHeight: 300, overflow: 'auto' },
          onScroll: (event) => {
            const listboxNode = event.currentTarget;
            if (
              listboxNode.scrollTop + listboxNode.clientHeight >=
              listboxNode.scrollHeight - 1
            ) {
              if (hasNextPage && fetchMore) {
                fetchMore();
              }
            }
          },
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Usuários do sistema"
            placeholder="Pesquisar usuário..."
            margin="dense"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {isFetchingNextPage && (
                    <CircularProgress color="inherit" size={20} />
                  )}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />

      <Box display="flex" mt={1}>
        <TextField
          fullWidth
          label="Adicionar email externo"
          placeholder="Digite o email completo"
          value={externalEmailInput}
          onChange={(e) => setExternalEmailInput(e.target.value)}
          margin="dense"
        />
        <IconButton
          onClick={handleAddExternalEmail}
          sx={{ ml: 1, mt: 'auto', mb: 'auto' }}
        >
          <Add />
        </IconButton>
      </Box>

      {value.length > 0 && (
        <List dense sx={{ mt: 2 }}>
          {value.map((recipient) => (
            <ListItem key={recipient.email}>
              <ListItemIcon>
                <AccountCircle fontSize="large" />
              </ListItemIcon>
              <ListItemText
                primary={recipient.email}
                secondary={recipient.isGuest ? "Convidado" : recipient.name}
              />
              <ListItemSecondaryAction>
                <Box display="flex" gap={1}>
                  {isNewViewer(recipient) ? (
                    <IconButton edge="end" title="Visualizador não salvo">
                      <ErrorOutlineRounded />
                    </IconButton>
                  ) : (
                    <IconButton
                      edge="end"
                      onClick={() => console.log('Gerar link para:', recipient.email)}
                      title="Copiar link de compartilhamento"
                    >
                      <InsertLink fontSize="medium" />
                    </IconButton>
                  )}
                  <IconButton
                    edge="end"
                    onClick={() => handleDeleteEmail(recipient.email)}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Box>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      )}

      <Dialog open={confirmDialogOpen} onClose={handleCancelDelete}>
        <DialogTitle>Cancelar convite</DialogTitle>
        <DialogContent>
          <Typography>
            Deseja realmente cancelar o convite para este processo?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete} variant="outlined">Cancelar</Button>
          <Button onClick={handleConfirmDelete} color="error">
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};