import { useEffect, useState } from "react";

import {
  Autocomplete,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Stack,
  TextField,
} from "@mui/material";

import "react-quill/dist/quill.snow.css";

import { useForm } from "react-hook-form";
import Modeler from "bpmn-js/lib/Modeler";
import { Element } from "bpmn-js/lib/model/Types";
import { enqueueSnackbar } from "notistack";
import { DynamicForm, DynamicFormComponentProps } from "../../../../../../components/DynamicForm";
import { types } from "../../../../../../components/DynamicForm/ComponentsModal";
import WarningIcon from "@mui/icons-material/Warning";
import { CodeEditor } from "./CodeEditor";
import axiosClient from "../../../../../../libs/axios";
import { useQuery } from "react-query";
import RichTextEditor from "./EmailEditor";

type PanelProps = {
  open: boolean;
  onClose: () => void;
  element: Element;
  modeler: Modeler | null;
};

export type FormData = {
  name: string;
  resolutionFormat: string;
  hasObservation: boolean;
  hasForm: boolean;
  hasDeadline: boolean;
  isPublic: boolean;
  formComponents: DynamicFormComponentProps[];
  hasAttachment: boolean;
  deadline: number;
  allowOtherProfilesToView: string[];
  embedCode: string;
  emailTo: number[];
  emailSubject: string;
  emailBody: string;
  externalEmails: string[];
};

interface User {
  id: number;
  name: string;
  email: string;
}

interface Group {
  id: number;
  name: string;
  users: User[];
}

export function Panel({ open, onClose, element, modeler }: PanelProps) {
  const typeElement = element.type;
  const elementLine = element.businessObject.lanes[0];

  const [externalEmails, setExternalEmails] = useState<string[]>([]);
  const [emailInput, setEmailInput] = useState('');

  const { handleSubmit, setValue, watch, register } = useForm<FormData>({
    defaultValues: {
      name: '',
      resolutionFormat: 'manual',
      hasForm: false,
      formComponents: [],
      hasObservation: false,
      hasAttachment: false,
      hasDeadline: false,
      isPublic: false,
      deadline: 0,
      embedCode: '',
      allowOtherProfilesToView: [],
      emailTo: [],
      emailSubject: "",
      emailBody: ""
    }
  });

  useEffect(() => {

    if (element.businessObject.formComponents) {
      let obj = (JSON.parse(element.businessObject.formComponents) || []).map((i: DynamicFormComponentProps) => {

        let info = types.find(t => t.type === i.type);
        i.icon = info?.icon || <WarningIcon />;
        return i;
      });

      setValue('formComponents', obj);
    }

    setValue('name', element.businessObject.name || '');
    setValue('resolutionFormat', element.businessObject.resolutionFormat || 'manual');
    setValue('hasObservation', element.businessObject.hasObservation === 'true');
    setValue('hasAttachment', element.businessObject.hasAttachment === 'true');
    setValue('hasForm', element.businessObject.hasForm === 'true');
    setValue('hasDeadline', (parseInt(element.businessObject.deadline) || 0) > 0);
    setValue('isPublic', element.businessObject.isPublic === 'true');
    setValue('deadline', parseInt(element.businessObject.deadline) || 0);
    setValue('embedCode', element.businessObject.embedCode || '');
    setValue('allowOtherProfilesToView', element.businessObject.allowOtherProfilesToView ? element.businessObject.allowOtherProfilesToView.split(',') : []);

    if (typeElement === "bpmn:SendTask") {
      let rawEmailTo = element.businessObject.emailTo;
      let parsedEmailTo: number[] = [];

      if (rawEmailTo) {
        try {
          const maybeArray = JSON.parse(rawEmailTo);
          if (Array.isArray(maybeArray)) {
            parsedEmailTo = maybeArray;
          } else {
            parsedEmailTo = String(rawEmailTo)
              .split(",")
              .map((s: string) => parseInt(s.trim()))
              .filter(Boolean);
          }
        } catch (err) {
          parsedEmailTo = String(rawEmailTo)
            .split(",")
            .map((s: string) => parseInt(s.trim()))
            .filter(Boolean);
        }
      }

      setValue("emailTo", parsedEmailTo);
      setValue("emailSubject", element.businessObject.emailSubject || "");
      setValue("emailBody", element.businessObject.emailBody || "");

      const rawExternalEmails = element.businessObject.externalEmails;
      const parsedExternalEmails = rawExternalEmails ? JSON.parse(rawExternalEmails) : [];
      setExternalEmails(parsedExternalEmails);
    }

  }, [element.businessObject, setValue, typeElement]);

  const onSubmit = (data: FormData) => {
    try {
      const modeling: any = modeler?.get('modeling');

      let customData: any = {
        'name': data.name,
        'custom:resolutionFormat': data.resolutionFormat,
        'custom:hasObservation': data.hasObservation.toString(),
        'custom:hasAttachment': data.hasAttachment.toString(),
        'custom:hasForm': data.hasForm.toString(),
        'custom:formComponents': JSON.stringify(data.formComponents.map(i => { let obj: any = { ...i }; delete obj.icon; return obj; })),
        'custom:deadline': data.deadline.toString(),
        'custom:embedCode': data.embedCode.toString(),
        'custom:isPublic': data.isPublic.toString(),
        'custom:allowOtherProfilesToView': data.allowOtherProfilesToView.join(','),
        'custom:externalEmails': JSON.stringify(externalEmails),
      };

      if (typeElement === "bpmn:SendTask") {
        customData["custom:emailTo"] = JSON.stringify(data.emailTo);
        customData["custom:emailSubject"] = data.emailSubject;
        customData["custom:emailBody"] = data.emailBody;
      }

      if(!data.name) { 
        enqueueSnackbar("Nome do elemento obrigatório", { variant: "error" });
      } else {
        modeling.updateProperties(element, customData);
        onClose();
      }
    } catch (error) {
      enqueueSnackbar('Erro ao salvar nó', { variant: 'error' });
    }
  };

  const hideCommonCheckboxes = typeElement === "bpmn:SendTask";


  const handleAddEmail = (email: string) => {
    if (validateEmail(email) && !externalEmails.includes(email)) {
      setExternalEmails([...externalEmails, email]);
      setEmailInput('');
    }
  };

  const handleRemoveEmail = (emailToRemove: string) => {
    setExternalEmails(externalEmails.filter(email => email !== emailToRemove));
  };

  const validateEmail = (email: string) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const handleEmailKeyDown = (e: React.KeyboardEvent) => {
    if (['Enter', 'Tab', ','].includes(e.key)) {
      e.preventDefault();
      const email = emailInput.trim();
      if (email) {
        handleAddEmail(email);
      }
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth={typeElement === "bpmn:ServiceTask" ? "md" : "sm"}>
      <DialogTitle>Editar {humanReadableType(typeElement)}</DialogTitle>
      <DialogContent>
        <FormControl fullWidth>
          <TextField
            {...register('name')}
            label="Nome"
            margin="dense"
            disabled={typeElement === "bpmn:StartEvent"}
          />

          <TextField
            label="Raia"
            margin="dense"
            value={elementLine.name}
            disabled
          />

          {
            (typeElement === "bpmn:ServiceTask") &&
            <CodeEditor 
              value={watch('embedCode')} 
              onChange={(newValue: string) => {
                setValue('embedCode', newValue);
              }}
            />
          }

          {
            (typeElement !== "bpmn:ServiceTask" && !hideCommonCheckboxes) && (
              <>
                <FormControlLabel
                  control={<Checkbox {...register('hasObservation')} checked={watch('hasObservation')} />}
                  label="Observação obrigatória"
                />

                <FormControlLabel
                  control={<Checkbox {...register('hasAttachment')} checked={watch('hasAttachment')} />}
                  label="Anexo obrigatório"
                />

                <FormControlLabel
                  control={<Checkbox {...register('hasForm')} checked={watch('hasForm')} />}
                  label="Preenchimento de formulário obrigatório"
                />
              </>
            )}

          {
            watch('hasForm') &&
            <DynamicForm
              values={watch('formComponents')}
              onChange={(components: DynamicFormComponentProps[]) => {
                setValue('formComponents', components);
              }}
            />
          }

          {
            (typeElement === "bpmn:Task" || typeElement === "bpmn:ExclusiveGateway") && (
              <>
                <FormControlLabel
                  control={<Checkbox {...register('hasDeadline')} checked={watch('hasDeadline')} />}
                  label="Definir prazo para resolução"
                />

                {
                  watch('hasDeadline') &&
                  <TextField
                    {...register('deadline')}
                    label="Qtd. de dias"
                    type="number"
                    margin="dense"
                    placeholder='Ex: 5'
                    required
                    sx={{ maxWidth: 200 }}
                  />
                }

                <FormControlLabel
                  control={<Checkbox {...register('allowOtherProfilesToView')} />}
                  label="Permitir outros perfis visualizar a resposta"
                />
              </>
            )
          }

          {
            (typeElement === "bpmn:StartEvent") && 
            <FormControlLabel
              control={<Checkbox {...register('isPublic')} checked={watch('isPublic')} />}
              label="Permitir que usuários não autenticados possam iniciar processo"
            />
          }

          {typeElement === "bpmn:SendTask" && (
            <>
              <Autocomplete
                multiple
                freeSolo
                options={[]}
                value={externalEmails}
                onChange={(_event, newValue) => {
                  setExternalEmails(newValue.filter(email => validateEmail(email)));
                }}
                inputValue={emailInput}
                onInputChange={(_event, newValue) => {
                  setEmailInput(newValue);
                }}
                renderTags={(value, getTagProps) =>
                  value.map((email, index) => (
                    <Chip
                      label={email}
                      {...getTagProps({ index })}
                      color="primary"
                      variant="outlined"
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Destinatário(s)"
                    margin="dense"
                    placeholder="E-mail"
                    fullWidth
                  />
                )}
                onKeyDown={(e) => {
                  if (['Enter', 'Tab', ','].includes(e.key)) {
                    e.preventDefault();
                    const email = emailInput.trim();
                    if (email && validateEmail(email) && !externalEmails.includes(email)) {
                      setExternalEmails([...externalEmails, email]);
                      setEmailInput('');
                    }
                  }
                }}
              />

              <TextField
                {...register("emailSubject")}
                label="Assunto"
                margin="dense"
              />

              <RichTextEditor
                value={watch("emailBody")}
                onChange={(value) => setValue("emailBody", value)}
              />
            </>
          )}
        </FormControl>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>
          Cancelar
        </Button>
        <Button onClick={handleSubmit(onSubmit)} variant="contained">
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function humanReadableType(type: string) {
  switch (type) {
    case 'bpmn:ExclusiveGateway': return 'condicional';
    case 'bpmn:ServiceTask': return 'tarefa automática';
    case 'bpmn:Task': return 'tarefa';
    case 'bpmn:StartEvent': return 'início';
    case 'bpmn:SendTask': return 'envio de e-mail';
  }
}