import {
    useTheme
} from "@mui/material/styles";
import {
    Box,
    Typography,
    Button,
    Chip,
    Link,
    IconButton,
    Tooltip,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AddIcon from "@mui/icons-material/Add";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import React, { useState } from "react";
import { ComponentsModal } from "./ComponentsModal";
import { EditComponentModal } from "./EditComponentModal";
import { PreviewModal } from "./PreviewModal";
import {
    DndContext,
    closestCenter,
    PointerSensor,
    useSensor,
    useSensors,
    DragEndEvent,
} from "@dnd-kit/core";
import {
    arrayMove,
    SortableContext,
    useSortable,
    verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { types } from './ComponentsModal';

interface BaseComponentProps {
    id: string;
    name: string;
    type: string;
    isRequired?: boolean;
    icon?: React.ReactNode;
    options?: string[];
    useDataSource?: boolean;
    dataSource?: string | null;
    mask?: string;
    files?: any[];
}

export interface DynamicFormComponentProps extends BaseComponentProps {
    components?: BaseComponentProps[];
}

type DynamicFormProps = {
    values: DynamicFormComponentProps[];
    allowUsingRegisteredForm?: boolean;
    allowPreview?: boolean;
    onChange: (components: DynamicFormComponentProps[]) => void;
};

interface SortableChipProps {
    id: string;
    component: DynamicFormComponentProps;
    onEdit: (component: DynamicFormComponentProps) => void;
}

const SortableChip: React.FC<SortableChipProps> = ({ id, component, onEdit }) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        isDragging,
    } = useSortable({ id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        opacity: isDragging ? 0.5 : 1,
        cursor: "grab",
        boxShadow: isDragging ? "0 4px 12px rgba(0, 0, 0, 0.2)" : "none",
    };

    return (
        <Chip
            ref={setNodeRef}
            {...attributes}
            {...listeners}
            variant="outlined"
            color="primary"
            onClick={() => onEdit(component)}
            key={component.id}
            label={component.isRequired ? `${component.name}*` : component.name}
            icon={
                component.icon && React.isValidElement(component.icon)
                    ? React.cloneElement(component.icon as React.ReactElement<any>, { fontSize: "small" })
                    : undefined
            }
            deleteIcon={<EditIcon sx={{ fontSize: "16px !important" }} />}
            clickable
            onDelete={() => onEdit(component)}
            sx={style}
        />
    );
};

export function DynamicForm({
    values = [],
    onChange,
    allowUsingRegisteredForm = false,
    allowPreview = true,
}: DynamicFormProps) {
    const [openComponentsModal, setOpenComponentsModal] = useState(false);
    const [showModalPreview, setShowModalPreview] = useState(false);
    const [editComponent, setEditComponent] = useState<DynamicFormComponentProps | null>(null);

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 5,
            },
        })
    );

    const handleDragEnd = (event: DragEndEvent) => {
        const { active, over } = event;

        if (active.id !== over?.id) {
            const oldIndex = values.findIndex((item) => item.id === active.id);
            const newIndex = values.findIndex((item) => item.id === over?.id);

            const newValues = arrayMove(values, oldIndex, newIndex);
            onChange(newValues);
        }
    };

    const handleCopy = () => {
        const valuesToCopy = values.map(({ icon, ...rest }) => ({
            ...rest,
            type: rest.type
        }));
        navigator.clipboard.writeText(JSON.stringify(valuesToCopy));
    };

    const handlePaste = async () => {
        try {
            const text = await navigator.clipboard.readText();
            const pastedValues = JSON.parse(text);
            
            const reconstructedValues = pastedValues.map((item: any) => {
                const typeConfig = types.find(t => t.type === item.type);
                return {
                    ...item,
                    icon: typeConfig?.icon,
                    options: item.options || typeConfig?.options
                };
            });
            
            onChange(reconstructedValues);
        } catch (error) {
            console.error("Erro ao colar os valores:", error);
        }
    };

    return (
        <>
            <Box component="section" sx={{ p: 2, border: "1px dashed grey", position: "relative" }}>
                {values.length === 0 && (
                    <>
                        <Box display="flex" alignItems="center" flexDirection="column">
                            <Button
                                variant="outlined"
                                startIcon={<AddIcon />}
                                onClick={() => setOpenComponentsModal(true)}
                            >
                                Adicionar componente
                            </Button>
                            {allowUsingRegisteredForm && (
                                <Typography variant="caption" mt={1}>
                                    ou <Link>clique aqui</Link> para utilizar um formulário já cadastrado
                                </Typography>
                            )}
                        </Box>
                    </>
                )}
                <DndContext
                    sensors={sensors}
                    collisionDetection={closestCenter}
                    onDragEnd={handleDragEnd}
                >
                    <SortableContext
                        items={values.map((item) => item.id)}
                        strategy={verticalListSortingStrategy}
                    >
                        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, paddingRight: "60px" }}>
                            {values.map((component: DynamicFormComponentProps) => (
                                <SortableChip
                                    key={component.id}
                                    id={component.id}
                                    component={component}
                                    onEdit={(comp) => setEditComponent(comp)}
                                />
                            ))}
                        </Box>
                    </SortableContext>
                </DndContext>

                <Box sx={{
                    position: "absolute",
                    bottom: 8,
                    right: 8,
                    display: "flex",
                    gap: 0.5
                }}>
                    <Tooltip title="Copiar">
                        <span>
                            <IconButton
                                size="small"
                                onClick={handleCopy}
                                disabled={values.length === 0}
                                sx={{ color: "action.disabled" }}
                            >
                                <ContentCopyIcon fontSize="small" />
                            </IconButton>
                        </span>
                    </Tooltip>
                    <Tooltip title="Colar">
                        <IconButton
                            size="small"
                            onClick={handlePaste}
                            sx={{ color: "action.disabled" }}
                        >
                            <ContentPasteIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>
                </Box>
            </Box>

            {values.length > 0 && (
                <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1, marginY: 2 }}>
                    <Button
                        variant="outlined"
                        startIcon={<AddIcon />}
                        onClick={() => setOpenComponentsModal(true)}
                    >
                        Adicionar componente
                    </Button>
                    {allowPreview && (
                        <Button
                            variant="outlined"
                            startIcon={<VisibilityIcon />}
                            onClick={() => setShowModalPreview(true)}
                        >
                            Visualizar
                        </Button>
                    )}
                </Box>
            )}

            <ComponentsModal
                open={openComponentsModal}
                onClose={() => setOpenComponentsModal(false)}
                onSelect={(component: DynamicFormComponentProps) => {
                    const newArrayComponents = [...values, component];
                    onChange(newArrayComponents);
                    setOpenComponentsModal(false);
                }}
            />

            {editComponent && (
                <EditComponentModal
                    onClose={() => setEditComponent(null)}
                    component={editComponent}
                    onChange={(component: DynamicFormComponentProps) => {
                        const newArrayComponents = [...values];
                        const foundIndex = newArrayComponents.findIndex((i) => i.id === component.id);
                        newArrayComponents[foundIndex] = component;
                        onChange(newArrayComponents);
                        setEditComponent(null);
                    }}
                    onDelete={(component: DynamicFormComponentProps) => {
                        const newArrayComponents = [...values];
                        const foundIndex = newArrayComponents.findIndex((i) => i.id === component.id);
                        newArrayComponents.splice(foundIndex, 1);
                        onChange(newArrayComponents);
                        setEditComponent(null);
                    }}
                />
            )}

            {showModalPreview && (
                <PreviewModal
                    components={values}
                    onClose={() => {
                        setShowModalPreview(false);
                    }}
                />
            )}
        </>
    );
}  