import { lazy } from "react";
import { Navigate, RouteObject } from "react-router-dom";
import PublicCreateProcess from "../screens/Public/CreateProcess";
import PublicTemplates  from "../screens/Public/Templates";
import { PublicProcessDetail } from "../screens/Public/ProcessDetail";

const Login = lazy(() => import("../screens/Login"));

export default function publicRoutes(): Array<RouteObject> {
  return [
    { path: "/login", element: <Login /> },
    { path: "/public/create-process/:identifier", element: <PublicCreateProcess /> },
    { path: "/public/view-process/:code/:token", element: <PublicProcessDetail /> },
    { path: "*", element: <Navigate to="/login" replace /> },
  ];
}