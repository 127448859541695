import { CircularProgress, CssBaseline, Dialog, DialogContent, DialogTitle, Typography, Alert, Box } from "@mui/material";
import { Suspense, useContext } from "react";
import { Outlet, useLocation, matchPath } from "react-router-dom";
import AppBar from "./AppBar";
import BackButtonBar from "./BackButtonBar";
import { CompanyContext } from "../contexts/CompanyContext";

export function AuthenticatedLayout() {

  const location = useLocation();
  const match = matchPath("/flow/:id", location.pathname) || matchPath("/datasource/:id", location.pathname);
  const { status, expire_at, plan } = useContext(CompanyContext);

  // Função auxiliar para calcular dias restantes
  const getDaysRemaining = () => {
    if (!expire_at) return 0;
    const now = new Date();
    const expirationDate = new Date(expire_at);
    const diffTime = expirationDate.getTime() - now.getTime();
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  };

  return (
    <>
      {match ? <BackButtonBar /> : <AppBar />}
      
      {plan === "TRIAL" && expire_at && (
        <Alert severity="warning" sx={{ 
          borderRadius: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          '& .MuiAlert-icon': {
            position: 'static',
            marginRight: 1,
            marginTop: 1,
            padding: 1
          },
          '& .MuiAlert-message': {
            padding: 1,
            marginTop: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          },
          '& .MuiAlert-action': {
            padding: 1,
            marginTop: 1,
            marginRight: 0
          }
        }}>
          Você está usando uma versão de avaliação. Restam {getDaysRemaining()} dia(s) para expirar.
        </Alert>
      )}

      <Dialog open={status !== "Active"} disableEscapeKeyDown>
        <DialogTitle>Conta Inativa</DialogTitle>
        <DialogContent>
          <Typography>
            Sua conta não está ativa no momento. Por favor, entre em contato com nosso suporte via WhatsApp para regularizar sua situação.
          </Typography>
          <Typography sx={{ mt: 2 }}>
            WhatsApp: <a href="https://wa.me/5531973370155">+55 (31) 97337-0155</a>
          </Typography>
        </DialogContent>
      </Dialog>

      <Suspense fallback={<CircularProgress />}>
        <CssBaseline />
        <Outlet />
      </Suspense>
    </>
  );
}