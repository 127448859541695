import { getDefaultProperties } from './DefaultProperties';

/**
 * CustomPaletteProvider customizes the BPMN modeler's palette, allowing the 
 * addition of specific elements like start events, tasks, external actions, gateways, and end events. 
 * It defines actions for creating these elements through drag-and-drop or click, specifying the group, 
 * icon, title, and actions for each element type.
 */

export default class CustomPaletteProvider {
  constructor(palette, create, elementFactory, modeling) {
    this._create = create;
    this._elementFactory = elementFactory;
    this._modeling = modeling;

    palette.registerProvider(this);
  }

  getPaletteEntries(element) {
    const { _create, _elementFactory, _modeling } = this;

    const createShape = (type) => {
      return (event) => {
        const shape = _elementFactory.createShape({ type: type });
        
        const defaultProperties = getDefaultProperties(type);

        // Adding property and custom patterns to the shape's businessObject
        Object.assign(shape.businessObject, defaultProperties);

        if (_create.start) {
          _create.start(event, shape);
        } else {
          _modeling.createShape(shape, { x: 100, y: 100 }, element);
        }
      };
    };

    return function(entries) {
      return {
/*         'create.start-event': {
          group: 'model',
          className: 'bpmn-icon-start-event-none',
          title: 'Inicio',
          action: {
            dragstart: createShape('bpmn:StartEvent'),
            click: createShape('bpmn:StartEvent')
          },
        }, */
        'create.task': {
          group: 'model',
          className: 'bpmn-icon-task menu-item-tour-0',
          title: 'Tarefa',
          action: { 
            dragstart: createShape('bpmn:Task'), 
            click: createShape('bpmn:Task')
          }
        },
        'create.gateway': {
          group: 'model',
          className: 'bpmn-icon-gateway-none menu-item-tour-1',
          title: 'Condicional',
          action: {
            dragstart: createShape('bpmn:ExclusiveGateway'),
            click: createShape('bpmn:ExclusiveGateway')
          }
        },
        'create.task-auto': {
          group: 'model',
          className: 'bpmn-icon-service-task menu-item-tour-2',
          title: 'Tarefa automática',
          action: { 
            dragstart: createShape('bpmn:ServiceTask'), 
            click: createShape('bpmn:ServiceTask')
          }
        },
        'create.send-task': {
          group: 'model',
          className: 'bpmn-icon-send-task menu-item-tour-3',
          title: 'Enviar Email',
          action: { 
            dragstart: createShape('bpmn:SendTask'),
            click: createShape('bpmn:SendTask')
          }
        },
        'create.end-event': {
          group: 'model',
          className: 'bpmn-icon-end-event-none menu-item-tour-4',
          title: 'Fim',
          action: {
            dragstart: createShape('bpmn:EndEvent'),
            click: createShape('bpmn:EndEvent')
          }
        }
      };
    };
  }
}

CustomPaletteProvider.$inject = [
  'palette',
  'create',
  'elementFactory',
  'modeling'
];