export function getDefaultProperties(type) {
  switch (type) {
    case 'bpmn:StartEvent':
      return { 'custom:deadline': '', 'custom:performTask': '', 'custom:status': '', 'custom:hasAttachment': '', 'custom:hasObservation': '', 'custom:advanceAutomatically': '' };
    case 'bpmn:Task':
      return { 'custom:deadline': '', 'custom:performTask': '', 'custom:status': '', 'custom:hasAttachment': '', 'custom:hasObservation': '', 'custom:advanceAutomatically': '' };
    case 'bpmn:ServiceTask':
      return { 'custom:embedCode': '' };
    case 'bpmn:ExclusiveGateway':
      return { 'custom:deadline': '', 'custom:performTask': '', 'custom:status': '', 'custom:hasAttachment': '', 'custom:hasObservation': '', 'custom:advanceAutomatically': '' };
    case 'bpmn:ParallelGateway':
      return { 'custom:deadline': '', 'custom:performTask': '', 'custom:status': '', 'custom:hasAttachment': '', 'custom:hasObservation': '', 'custom:advanceAutomatically': '', 'custom:embedCode': '' };
    case 'bpmn:SendTask':
      return {
        'custom:deadline': '', 'custom:performTask': '', 'custom:status': '', 'custom:hasAttachment': '', 'custom:hasObservation': '', 'custom:advanceAutomatically': '', 'custom:emailTo': '', 'custom:emailSubject': '', 'custom:emailBody': '' };
    case 'bpmn:EndEvent':
      return { 'custom:deadline': '', 'custom:performTask': '', 'custom:status': '', 'custom:hasAttachment': '', 'custom:hasObservation': '', 'custom:advanceAutomatically': '' };
    default:
      return {};
  }
}