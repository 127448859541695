import { 
    AppBar, Box, Chip, CircularProgress, Container, Dialog, DialogContent, Divider, Grid, Typography, Step, StepContent, StepLabel, Stepper, Toolbar 
  } from "@mui/material";
  import { useQuery } from "react-query";
  import axiosClient from "../../../libs/axios";
  import { formatDate } from "../../../utils/formatDate";
  import { situationLabel } from "../../../utils/situationLabel";
  import { situationColor } from "../../../utils/situationColor";
  import { ViewFlow } from "../../../components/ViewFlow";
  import { DynamicFormRenderResponse } from "../../../components/DynamicForm/DynamicFormRenderResponse";
  import { DynamicFormComponentProps } from "../../../components/DynamicForm";
  import { formatExpiresAt } from "../../../utils/formatExpiresAt";
  import { useParams, useSearchParams } from "react-router-dom";
  
  export function PublicProcessDetail() {
    const { code, token } = useParams<{ code: string; token: string }>();
    
    const identifier = window.location.hostname.split(".")[0];
  
    const { data, isLoading } = useQuery({
      queryKey: ["public_process", code, identifier],
      queryFn: () => axiosClient.get(`/public/process-detail?identifier=${identifier}&code=${code}&token=${token}`),
      keepPreviousData: true,
      cacheTime: 0,
    });
  
    const process = data?.data;
  
    return (
      <Dialog fullScreen open={true} PaperProps={{ style: { pointerEvents: 'none' } }}>
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Visualização do Processo #{code}
            </Typography>
          </Toolbar>
        </AppBar>
  
        <DialogContent>
          <Container>
            {isLoading ? (
              <Box display="flex" justifyContent="center" py={3}>
                <CircularProgress size={40} />
              </Box>
            ) : process ? (
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4} md={3}>
                  <Box 
                    mb={2}
                    sx={{
                      position: "sticky",
                      top: "1px",
                      maxHeight: "calc(100vh - 100px)",
                      overflowY: "auto"
                    }}
                  >
                    <Box mb={2}>
                      <Box display="flex" alignItems="flex-start" justifyContent="space-between">
                        <Box flex={1} mr={1}>
                          <Typography variant="caption">Fluxo</Typography>
                          <Typography variant="h6" sx={{ wordBreak: 'break-word' }}>
                            {process.flow.name}
                          </Typography>
                        </Box>
                        {/* <ViewFlow flowId={process.flow.id} version={process.flow_version} nameFlow={process.flow.name} /> */}
                      </Box>
                      <Box mt={1}>
                        <Chip label={`Versão: ${process.flow_version}`} size="small"/>
                      </Box>
                    </Box>
                    <Box mb={2}>
                      <Typography variant="caption">Criado por</Typography>
                      <Typography variant="h6">{process.user?.name || 'Anônimo'}</Typography>
                    </Box>
                    <Box mb={2}>
                      <Typography variant="caption">Data de criação</Typography>
                      <Typography variant="h6">{formatDate(process.created_at)}</Typography>
                    </Box>
                    <Box mb={6} display="flex" flexDirection="column" alignItems="flex-start">
                      <Typography variant="caption">Situação</Typography>
                      <Chip
                        sx={{ fontSize: "14px" }}
                        label={situationLabel(process.situation)} 
                        color={situationColor(process.situation)}
                      />
                    </Box>
                  </Box>
                </Grid>
  
                {/* Divisor vertical */}
                <Grid item display="flex" justifyContent="center" xs={0} sm={1}>
                  <Divider sx={{ width: 2 }} orientation="vertical" />
                </Grid>
  
                {/* Painel direito: Tarefas */}
                <Grid item xs={12} sm={7} md={8}>
                  <Typography variant="h5" mb={2}>Tarefas</Typography>
                  <Stepper activeStep={process.tasks.length - 1} orientation="vertical">
                    {process.tasks.map((task: any, index: number) => (
                      <Step key={task.id} expanded completed={task.type === 'Start' || task.resolved_at}>
                        <StepLabel 
                          icon={index === 0 && !task.resolved_at ? (
                            <Typography
                              style={{
                                backgroundColor: '#0d47a1',
                                color: 'white',
                                width: 27,
                                height: 27,
                                borderRadius: '50%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                              }}
                            >
                              {process.tasks.length}
                            </Typography>
                          ) : ''} 
                          optional={
                            <>
                              {task.resolved_at ? (
                                <>
                                  <Typography variant="caption" component="div">
                                    {task.type === 'End' ? 'Finalizado' : (task.type === 'Start' ? 'Iniciado' : 'Resolvido')} em {formatDate(task.resolved_at)}
                                  </Typography>
                                  {task.resolved_by && (
                                    <Typography variant="caption" component="div">
                                      por {task.resolved_by.name}{task.group ? ` / ${task.group.name}` : ""}
                                    </Typography>
                                  )}
                                </>
                              ) : (
                                <>
                                  <Typography variant="caption" component="div">
                                    Em andamento{task.group ? ` / ${task.group.name}` : ""}
                                  </Typography>
                                  {task.expires_at && (
                                    <Typography variant="caption" component="div">
                                      Expira em {formatExpiresAt(task.expires_at)}
                                    </Typography>
                                  )}
                                </>
                              )}
                            </>
                          }
                        >
                          <Typography variant="body1">{task.type === 'End' ? 'Fim' : task.title}</Typography>
                        </StepLabel>
                        <StepContent>
                          {task.resolved_at && (
                            <Box>
                              {task.type === 'Conditional' && (
                                <Typography variant="caption" component="div">
                                  Opção escolhida: {task.chosen}
                                </Typography>
                              )}
                              {task.form && task.form_response && (
                                <DynamicFormRenderResponse 
                                  alwaysExpanded 
                                  form={task.form as DynamicFormComponentProps[]} 
                                  formResponse={task.form_response} 
                                  files={task.files}
                                />
                              )}
                              {task.observation && (
                                <Typography variant="caption" component="div" my={0.5}>
                                  <b>Observações:</b> {task.observation}
                                </Typography>
                              )}
                              {task.files && task.files.filter((f: any) => !f.form_response_ref).map((f: any) => (
                                <Box key={f.id} display="flex" gap={1} alignItems="center">
                                  <Typography variant="caption">{f.name}</Typography>
                                </Box>
                              ))}
                            </Box>
                          )}
                        </StepContent>
                      </Step>
                    ))}
                  </Stepper>
                </Grid>
              </Grid>
            ) : (
              <Typography variant="body1">Processo não encontrado.</Typography>
            )}
  
          </Container>
        </DialogContent>
      </Dialog>
    );
  }
  