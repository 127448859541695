import { useQuery } from "react-query"
import { useLocation, useParams } from "react-router-dom"
import axiosClient from "../../../libs/axios";
import { useEffect, useRef, useState } from "react"
import { enqueueSnackbar } from "notistack"
import Modeler from "bpmn-js/lib/Modeler"
import { BpmnPropertiesPanelModule, BpmnPropertiesProviderModule } from "bpmn-js-properties-panel";
import { CustomPropertiesModule } from "../../../libs/bpmn-js/custom-modeler/properties"
import { CustomContextPadModule } from "../../../libs/bpmn-js/custom-modeler/contextPad"
import { CustomRendererModule } from "../../../libs/bpmn-js/custom-modeler/renderer"
import { CustomPaletteModule } from "../../../libs/bpmn-js/custom-modeler/palette"
import { customModdle } from "../../../libs/bpmn-js/custom-modeler/customModdle"
import 'bpmn-js/dist/assets/diagram-js.css';
import 'bpmn-js/dist/assets/bpmn-font/css/bpmn.css';
        
import 'bpmn-js/dist/assets/bpmn-font/css/bpmn-embedded.css';
import 'bpmn-js/dist/assets/bpmn-font/css/bpmn-codes.css';
import 'bpmn-js/dist/assets/bpmn-font/css/bpmn.css';
import 'bpmn-js/dist/assets/diagram-js.css';
import { Box, CircularProgress, Fab, Tooltip, Typography } from "@mui/material"
import { PropertiesView } from "../../Settings/Flows/Detail/PropertiesView"
import { useNavigate } from 'react-router-dom'

import SaveIcon from "@mui/icons-material/Save";

interface TemplateData  {
    data: {
        id: number
        name: string
        description: string
        type: string
        categories: []
        icon: {
            name: string
            color: string
        }
        spec: string
        code: string
        user: string
    }
}

export default function Template () {
    const {id} = useParams<{id:string}>()
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)
    const userToken = queryParams.get("user")
    const navigate = useNavigate()
    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [type, setType] = useState('')
    const [categories, setCategories] = useState([])
    const [nameIcon, setNameIcon] = useState('')
    const [colorIcon, setColorIcon] = useState('')
    const [spec, setSpec] = useState('')
    const [code, setCode] = useState('')
    const [user, setUser] = useState('')
    const modelerRef = useRef<Modeler | null>(null)
    const [modelerReady, setModelerReady] = useState(false)
    const container = useRef<HTMLDivElement>(null)
    const propertiesPanel = useRef<HTMLDivElement>(null)
    const [originalSpec, setOriginalSpec] = useState<string | null>(null)
    const [isDirty, setIsDirty] = useState<boolean>(false)
    const [isSaving, setIsSaving] = useState<boolean>(false)
    
    const {isLoading} = useQuery(
        ['template', id],
        () => axiosClient.get(`/public/detail-template/${id}`, {
            params: {
                user: userToken
            }
        }),
        {
            enabled: !!id,
            onSuccess: (data: TemplateData) => {
                setName(data.data.name)
                setDescription(data.data.description)
                setType(data.data.type)
                setCategories(data.data.categories)
                setNameIcon(data.data.icon.name)
                setColorIcon(data.data.icon.color)
                setSpec(data.data.spec)
                setCode(data.data.code)
                setOriginalSpec(data.data.spec)
                setUser(data.data.user)
            }, 
            onError: (error:any) => {
                    enqueueSnackbar('Template não encontrado', { variant: 'error' })
                    navigate('/login')
            }
        }
    )
        
    useEffect(() => {
        if(!modelerRef.current && spec){
            let modeler = new Modeler({
                container: container.current || '#bpmnview',
                keyboard: { bindTo: window },
                propertiesPanel: { parent: propertiesPanel.current },
                additionalModules: [
                    BpmnPropertiesPanelModule,
                    BpmnPropertiesProviderModule,
                    CustomPaletteModule,
                    CustomPropertiesModule,
                    CustomContextPadModule,
                    CustomRendererModule,
                ], 
                moddleExtensions: {custom: customModdle}
            })
            modeler.importXML(spec).then(() => {
                setModelerReady(true)
            })

            modeler.on('commandStack.changed', () => {
                modeler.saveXML({format: true}).then(({xml}) => {
                    if(originalSpec && xml !== originalSpec) {
                        setIsDirty(true)
                    } else{
                        setIsDirty(false)
                    }
                })
            })
            modelerRef.current = modeler
        }
    }, [spec, originalSpec])

    useEffect(() => {
        const handleBeforeUnload = (e: BeforeUnloadEvent) => {
            if (isDirty) {
                e.preventDefault();
                e.returnValue = '';
            }
        };
 
        if (isDirty) {
            window.addEventListener('beforeunload', handleBeforeUnload);
        } else {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        }
 
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [isDirty]);
 
    const handleSave = async () => {
        setIsSaving(true);
        
        if (modelerRef.current) {
            try {
                const { xml } = await modelerRef.current.saveXML({ format: true });
   
                const parser = new DOMParser();
                const xmlDoc = parser.parseFromString(xml as string, "application/xml");
   
                const nodeElements = xmlDoc.getElementsByTagName("bpmn:task");
                const arrowElements = xmlDoc.getElementsByTagName("bpmn:sequenceFlow");
                const gatewayElements = xmlDoc.getElementsByTagName("bpmn:exclusiveGateway");
                const sendTaskElements = xmlDoc.getElementsByTagName("bpmn:sendTask");
   
                let hasErrors = false;
   
                for (let i = 0; i < nodeElements.length; i++) {
                    const element = nodeElements[i];
                    const name = element.getAttribute("name");
                    if (!name || name.trim() === "") {
                        hasErrors = true;
                        enqueueSnackbar('Existem tarefas sem nome no fluxo', { variant: 'error' });
                        break;
                    }
                }
   
                if (!hasErrors) {
                    for (let i = 0; i < gatewayElements.length; i++) {
                        const element = gatewayElements[i];
                        const name = element.getAttribute("name");
                        if (!name || name.trim() === "") {
                            hasErrors = true;
                            enqueueSnackbar('Existem condicionais sem nome no fluxo', { variant: 'error' });
                            break;
                        }
                    }
                }
   
                if (!hasErrors) {
                    for (let i = 0; i < arrowElements.length; i++) {
                        const element = arrowElements[i];
                        const sourceRef = element.getAttribute("sourceRef");
                        const name = element.getAttribute("name");
   
                        const definitions = modelerRef.current?.getDefinitions();
                        const sourceElement = definitions?.rootElements
                            .flatMap((root: any) => root.flowElements || [])
                            .find((el: any) => el.id === sourceRef);
   
                        if (sourceElement && sourceElement.$type.includes("Gateway")) {
                            if (!name || name.trim() === "") {
                                hasErrors = true;
                                enqueueSnackbar('Existem setas de condicional sem nome', { variant: 'error' });
                                break;
                            }
                        }
                    }
                }
   
                if (!hasErrors) {
                    for (let i = 0; i < sendTaskElements.length; i++) {
                        const sendTask = sendTaskElements[i];
                        const sendTaskId = sendTask.getAttribute("id");
   
                        let outgoings = 0;
                        for (let j = 0; j < arrowElements.length; j++) {
                           const seqFlow = arrowElements[j];
                            if (seqFlow.getAttribute("sourceRef") === sendTaskId) {
                                outgoings++;
                            }
                        }
   
                        if (outgoings > 1) {
                            hasErrors = true;
                            enqueueSnackbar('Existem tarefas de envio de e-mail com mais de uma saída', { variant: 'error' });
                            break;
                         }
                    }
                }
   
                if (hasErrors) {
                    setIsSaving(false);
                    return;
                }
 
                await axiosClient.put(`/public/${id}/spec`, { id, spec: xml });
                enqueueSnackbar('Fluxo salvo com sucesso', { variant: 'success' });
                setOriginalSpec(xml as string);
                setIsDirty(false);
   
            } catch (error) {
                console.error(error);
                enqueueSnackbar('Erro ao salvar o fluxo', { variant: 'error' });
            } finally {
                 setIsSaving(false);
            }
        }
    };
    return (
        <>
            {isLoading ? 
                <Box display="flex">
                    <CircularProgress sx={{ margin: 2 }}/>
                </Box> : (
                    <>
                        <Box display='flex' width="100%" height="100vh">
                            {isDirty && (
                                <Box position="absolute" sx={{ backgroundColor: '#ffd600', width: '100%' }}>
                                    <Typography variant="body1" align="center">Atenção! Você tem alterações não salvas no fluxo</Typography>
                                </Box>
                            )}
                            <div ref={container} style={{
                                border: "1px solid #000000",
                                height: '100%',
                                width: '100%',
                            }}/>

                            <PropertiesView modeler={modelerRef ? modelerRef.current : null} modelerReady={modelerReady} user={userToken}/>

                            <Tooltip title="Salvar Fluxo">
                                <Fab color="primary" aria-label="save" style={{
                                    position: 'absolute',
                                    bottom: 80,
                                    left: 20,
                                }} onClick={handleSave} disabled={isSaving}>
                                    {isSaving ? <CircularProgress size={24}/> : <SaveIcon/>}
                                </Fab>
                            </Tooltip>
                        </Box>
                    </>
                )
            }
        </>
    )
}